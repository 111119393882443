export const validTestStripeCardNumber = '4242424242424242'

// This is important to match up with VCR in saving the card
export const testPaymentMethodId = "pm_0NAX3rpbW7q7zYPt37GU9M7E"

export const simulatedSetupIntentResponse = {
  "id": "seti_0N7W88pbW7q7zYPtCyPMgbPl",
  "object": "setup_intent",
  "automatic_payment_methods": null,
  "cancellation_reason": null,
  "created": 1684036916,
  "description": null,
  "last_setup_error": null,
  "livemode": false,
  "next_action": null,
  "payment_method": testPaymentMethodId,
  "payment_method_types": [
    "card"
  ],
  "status": "succeeded",
  "usage": "off_session"
}
