import * as React from 'react';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faClock } from '@fortawesome/free-regular-svg-icons';
import { faAsterisk, faCheckCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import userService from 'javascripts/services/user-service';

export type CreditCardPanelProps = {
  card: CreditCardData;
  selected: boolean;
  editable?: boolean;
  showPreferred?: boolean;
  hideCheckbox?: boolean;
  setPaymentMethod?: (address: CreditCardData) => void;
  onDelete?: () => void;
};

const CreditCardPanel = (props: CreditCardPanelProps) => {
  const [deleting, setDeleting] = useState(false);
  const { card, selected, editable, showPreferred, hideCheckbox, setPaymentMethod, onDelete } = props;

  const deleteCard = async () => {
    setDeleting(true);
    const response = await userService.deleteCard(card);
    if (response.result === 'error') {
      const message = response.errors ? response.errors[0] : 'Failed to delete card.';
      alert(message);
    } else {
      if (onDelete) onDelete();
    }
    setDeleting(false);
  }

  return (
    <label className={`ui-selection-panel credit-card ${ selected ? 'selected' : 'muted' } ${ hideCheckbox ? 'hide-checkbox' : '' }`}>
      <input type="radio" name="payment-method" checked={selected} onChange={() => setPaymentMethod && setPaymentMethod(card)} className="selection-checkbox" />
      <div className="checkbox unchecked"><FontAwesomeIcon icon={faCircle} /></div>
      <div className="checkbox checked"><FontAwesomeIcon icon={faCheckCircle} /></div>
      <div className="title">
        <span className="icon" key={card.brand}>
          <i className={card.brand_fa}></i>
        </span>
        <span className="number">
          <span className="number-group asterisks">
            <FontAwesomeIcon icon={faAsterisk} />
            <FontAwesomeIcon icon={faAsterisk} />
            <FontAwesomeIcon icon={faAsterisk} />
            <FontAwesomeIcon icon={faAsterisk} />
          </span>
          <span className="number-group">
            {card.last4}
          </span>
          { showPreferred && card.user_default ? <span className="badge">Default</span> : null }
        </span>
      </div>
      <div className="content">
        <strong>{card.name}</strong> <FontAwesomeIcon icon={faClock} /> {card.exp_month}/{card.exp_year % 100}
      </div>

      { editable ?
        <div className="panel-actions">
          <button type="button" className={`panel-action ${ deleting ? 'loading' : '' }`} onClick={deleteCard}>
            Delete
            <span className="icon">
              <FontAwesomeIcon icon={faTrashAlt} />
            </span>
          </button>
        </div>
      : null }
    </label>
  );
}

export default CreditCardPanel;
