import * as React from 'react';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SaleThumbnail from './SaleThumbnail';

const LockerItem = (props: LockerItemData) => {
  const { sale, qty, purchase_date } = props;
  const newSale = !!props.new;
  const purchaseDate = purchase_date;

  return (
    <div className={`ui-locker-item ${ newSale ? 'new' : '' }`}>
      <SaleThumbnail sale={sale} />
      <div className="content">
        <div className="producer">{sale.product.producer.name}</div>
        <div className="name">{sale.product.name}</div>
        <div className="details">
          <div className="quantity">
            { qty }×
          </div>
          { newSale ?
            <div className="new">
              New <FontAwesomeIcon icon={faPlusCircle} />
            </div>
          :
            <div className="purchase-date">
              {purchaseDate}
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default LockerItem;
