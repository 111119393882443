import axios from 'axios';
import { StripeElements } from '@stripe/stripe-js';

import { simulatedSetupIntentResponse, validTestStripeCardNumber } from 'javascripts/utils/const';

type ShippingMethod = {
  id: number;
  code: string;
  name: string;
}

type StateConfig = {
  id: number;
  code: string;
  name: string;
  default_shipping_method: ShippingMethod;
  shipping_methods: ShippingMethod[];
  require_phone_number: boolean;
}

type CreateAddressResponse = {
  result: 'error' | 'success';
  errors: string[];
  address?: AddressData;
}

type EditAddressResponse = {
  result: 'error' | 'success';
  errors: string[];
  address?: AddressData;
}

type DeleteAddressResponse = {
  result: 'error' | 'success';
  errors: string[];
}

type GetCarrierHoldLocationsResponse = {
  result: 'error' | 'success';
  errors: string[];
  addresses?: AddressData[];
}

type VerifyAddressResponse = {
  result: 'error' | 'success';
  errors: string[];
  addresses?: AddressData[];
}

export type SetupIntentResponse = {
  result: 'error' | 'success';
  client_secret: string;
}

type CardConfirmResponse = {
  error?: { message: string, type: string };
  payment_method?: string;
  created?: number;
  id?: string;
}

type CreateCardResponse = {
  result: 'error' | 'success';
  errors: string[];
  card?: CreditCardData;
}

type DeleteCardResponse = {
  result: 'error' | 'success';
  errors: string[];
}

export class UserService {
  private getStatesConfig = () => {
    let statesConfig: StateConfig[] = [];
    if ('STATE_SHIPPING_METHODS' in window) {
      statesConfig = (window as any).STATE_SHIPPING_METHODS;
    }

    return statesConfig;
  }

  getStateOptions = () => {
    const config = this.getStatesConfig();
    return config.map(x => ({ value: x.code, text: x.name }));
  }

  getDefaultState = () => {
    const config = this.getStatesConfig();
    return config[0].code;
  }

  getDefaultShippingCarrier = (stateCode: string) => {
    const config = this.getStatesConfig();
    const sm = config.find(x => x.code.toLocaleUpperCase() === stateCode.toLocaleUpperCase())?.default_shipping_method;
    if (!sm) return;
    return sm.id;
  }

  getShippingCarrierOptions = (stateCode: string) => {
    const config = this.getStatesConfig();
    const state = config.find(x => x.code === stateCode);
    if (!state) return [];
    return state.shipping_methods.map(x => ({ value: x.id.toString(), text: x.name }));
  }

  isPhoneNumberRequired = (stateCode: string) => {
    const config = this.getStatesConfig();
    const state = config.find(x => x.code === stateCode);
    if (!state) return false;
    return state.require_phone_number;
  }

  /**
   * Adds a new address under the user.
   * @param address New address data.
   */
  createAddress = async (address: AddressFormData, setAsDefault = false): Promise<CreateAddressResponse> => {
    try {
      const response = await axios.post<CreateAddressResponse>('/user/addresses', {
        address_attributes: address,
        set_as_default: setAsDefault
      });
      return response.data;
    } catch(e: any) {
      if (e.response?.data) return e.response.data as CreateAddressResponse;
      throw e;
    }
  }

  /**
   * Edits an existing address under the user.
   * @param address New address data.
   */
  editAddress = async (address: AddressFormData): Promise<EditAddressResponse> => {
    try {
      const response = await axios.patch<EditAddressResponse>(`/user/addresses/${address.id}`, {
        address_attributes: address
      });
      return response.data;
    } catch(e: any) {
      if (e.response?.data) return e.response.data as EditAddressResponse;
      throw e;
    }
  }

  /**
   * Deletes a user's saved address.
   * @param address address to delete.
   */
  deleteAddress = async (address: AddressData): Promise<DeleteAddressResponse> => {
    try {
      const response = await axios.delete<DeleteAddressResponse>(`/user/addresses/${address.id}`);
      return response.data;
    } catch(e: any) {
      if (e.response?.data) return e.response.data as DeleteAddressResponse;
      throw e;
    }
  }

  /**
   * Get a list of supported carrier hold locations from wineshipping.
   * @param location Location data.
   */
  getCarrierHoldLocations = async (location: LocationData): Promise<GetCarrierHoldLocationsResponse> => {
    try {
      const response = await axios.get<GetCarrierHoldLocationsResponse>('/user/addresses/carrier_hold_locations', {
        params: {
          location: location
        }
      });
      return response.data;
    } catch(e: any) {
      if (e.response?.data) return e.response.data as GetCarrierHoldLocationsResponse;
      throw e;
    }
  }

  verifyAddress = async (address: AddressFormData): Promise<VerifyAddressResponse> => {
    try {
      const response = await axios.get<VerifyAddressResponse>(`/user/addresses/verify`, {
        params: {
          address_attributes: address
        }
      });
      return response.data;
    } catch(e: any) {
      if (e.response?.data) return e.response.data as VerifyAddressResponse;
      throw e;
    }
  }

  createSetupIntent = (billingDetails: NewCreditCardData, elements: StripeElements): Promise<CardConfirmResponse> => {
    return new Promise<CardConfirmResponse>(async (resolve) => {
      try {
        // @ts-ignore
        window.STRIPE.confirmSetup({
          elements,
          confirmParams: {
            payment_method_data: {
              billing_details: {
                name: billingDetails.card_name
              }
            }
          },
          redirect: 'if_required'
        }).then(function(data: any) {
          // @ts-ignore
          if (window.CYPRESS && elements.getElement('cardNumber') === validTestStripeCardNumber) {
            resolve(simulatedSetupIntentResponse);
          }

          if (data.error) resolve(data);
          resolve(data.setupIntent);
        });
      } catch (err) {
        resolve({ error: { message: 'Failed to create setup intent/payment method. Please try again later.', type: 'UNEXPECTED_ERROR' } });
      };
    });
  }

  /**
   * Adds a new credit card under the user.
   * @param address New address data.
   */
  createCard = async (billingDetails: NewCreditCardData, elements: StripeElements, setAsDefault = false): Promise<CreateCardResponse> => {
    try {
      const setupIntentResponse = await this.createSetupIntent(billingDetails, elements);

      if (!setupIntentResponse) return { result: 'error', errors: ['Failed to load Stripe. Please try again later.'] };
      if (setupIntentResponse.error) return { result: 'error', errors: [setupIntentResponse.error.message] };

      const response = await axios.post<CreateCardResponse>('/user/saved_credit_cards', {
        card_token: setupIntentResponse.payment_method,
        set_as_default: setAsDefault
      });
      return response.data;
    } catch(e: any) {
      if (e.response?.data) return e.response.data as CreateCardResponse;
      throw e;
    }
  }

  /**
   * Deletes a user's saved credit card.
   * @param card card to delete.
   */
  deleteCard = async (card: CreditCardData): Promise<DeleteCardResponse> => {
    try {
      const response = await axios.delete<DeleteCardResponse>(`/user/saved_credit_cards/${card.id}`);
      return response.data;
    } catch(e: any) {
      if (e.response?.data) return e.response.data as DeleteCardResponse;
      throw e;
    }
  }
}

const userService = new UserService();
export default userService;
