import * as React from 'react';
import { useCallback, useContext, useState } from 'react';
import { round } from 'lodash-es';
import Tippy from '@tippyjs/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCheckCircle, faQuestionCircle, faTimes } from '@fortawesome/free-solid-svg-icons';

import { CheckoutContext } from './CheckoutPage';
import useCheckout from 'javascripts/utils/useCheckout';
import { formatCityStateZip, formatStreet } from 'javascripts/utils/address';
import { CostSummaryRow, Savings, generateCostSummary } from 'javascripts/utils/cart';
import Input from './Input';
import CartItem from './CartItem';
import shippingCornerBottles from '../../assets/images/checkout/shipping-corner-bottles.svg';

const CheckoutPurchaseSummary = (props: { ctx: ReturnType<typeof useCheckout>, readonly: boolean }) => {
  const { ctx, readonly } = props;
  const [state, {}] = ctx;

  const cartItems = [];
  const items = state.preview?.line_items || [];
  for (const item of items) {
    // calculate item totals with proper rounding
    const itemTotal = round((item.sale.price.cents * item.qty) / 100.0);

    if (readonly) {
      cartItems.push(
        <CartItem key={item.sale.id} item={item} />
      );
    } else {
      cartItems.push(
        <div key={item.sale.id} className="summary-line-item">
          <div className="details">
            <div className="producer">{item.sale.product.producer.name}</div>
            <div className="name">{item.sale.product.name}</div>
          </div>
          <div className="quantity">{item.qty}×</div>
          <div className="total-cost">${itemTotal}</div>
        </div>
      );
    }
  }

  return (
    <div className="panel-body purchase-summary">
      { cartItems.length > 0 ? cartItems : (
        <div className="cart-empty">
          Your cart is currently empty.
        </div>
      ) }
    </div>
  );
};

const CheckoutShippingSummary = (props: { ctx: ReturnType<typeof useCheckout> }) => {
  const { ctx } = props;
  const [state, {}] = ctx;
  const { order } = state;

  if (!order.has_shipping) {
    <div className="panel-body muted shipping-summary">
      <div className="label">Shipping Overview</div>
    </div>
  }

  let content = <></>;

  if (!order.has_shipping) {
    content = <div className="shipping-empty">No items will be shipped for this order.</div>;
  } else if (!order.selected_address) {
    content = <div className="shipping-empty">Please select a shipping address.</div>;
  } else {
    const addr = order.selected_address;
    const count = state.preview.bottles_for_shipping || 0;

    content = (
      <>
        <div className="shipping-info">
          <div className="address">
            { addr.name ? <div className="address-row name">{addr.name}</div> : null }
            { addr.company ? <div className="address-row company">{addr.company}</div> : null }
            <div className="address-row">{ formatStreet(addr) }</div>
            <div className="address-row">{ formatCityStateZip(addr) }</div>
          </div>
          <div className="viz">
            <div className="viz-square">
              <div className="count">{ count }</div>
              <img className="viz-icon" src={shippingCornerBottles} alt="" />
            </div>
          </div>
        </div>
        <div className="summary-text">
          { count } bottle{ count == 1 ? '' : 's' } will be shipped
          { order.ship_on
            ? ' on ' + order.ship_on
            : ' immediately'
          }.
        </div>
        { order.zone_skipped ?
          <div className="temp-controlled">
            Temp Controlled Shipping Included
            <FontAwesomeIcon icon={faCheckCircle} />
          </div>
        : null }
      </>
    );
  }

  return (
    <div className="panel-body muted shipping-summary">
      <div className="label">Shipping Overview</div>
      { content }
    </div>
  );
}

const CheckoutPaymentSummary = (props: { ctx: ReturnType<typeof useCheckout>, costs: CostSummaryRow[], readonly: boolean }) => {
  const [couponInput, setCouponInput] = useState('');
  const { ctx, costs, readonly } = props;
  const [state, { setCouponCode }] = ctx;
  const { order, user, preview } = state;
  const coupon = preview.coupon;

  React.useEffect(() => {
    const queryStringCoupon = window.localStorage.getItem('QUERY_STRING_COUPON')
  
    if (queryStringCoupon) {
      setCouponCode(queryStringCoupon.toLocaleUpperCase().trim());
    }
  }, [])

  const updateCouponCode = useCallback(() => {
    setCouponCode(couponInput.toLocaleUpperCase().trim());
  }, [setCouponCode, couponInput]);

  const resetCouponCode = useCallback(() => {
    setCouponCode();
    setCouponInput('');
  }, [setCouponCode, setCouponInput]);

  const disabled = coupon && coupon.code === order.coupon_code;

  if (readonly) return (
    <div className="panel-body payment-summary">
      <div className="summary-info">
        { costs.map(row => (
          <div key={row.name} className={`cost-row cost-${row.type}`}>
            <div className="label">
              { (() => {
                  if (row.type === 'tax' && row.caSalesTax) {
                    return(
                      <Tippy content="Regardless of your shipping address, this transaction occurs at our business in California, and all California sales require California sales tax.">
                        <span>{row.name}</span>
                      </Tippy>
                    )
                  } else if (row.type === 'shipping' && row.withLockerShipping) {
                    return(
                      <Tippy content="Lockers allow you to purchase smaller quantities of wine and store it with us until your Locker is completely full. If you ship a Locker that is not full yet, you will be charged the normal ground shipping amount.">
                        <span>{row.name}</span>
                      </Tippy>
                    )
                  } else {
                    return(
                    <span>{row.name}</span>
                    )
                  }
                })()
              }
            </div>
            <div className="amount">${row.value.str}</div>
          </div>
        )) }
      </div>
    </div>
  );

  return (
    <>
      <div className="panel-body payment-summary">
        { !user.at_cost ?
          <div className="voucher-code grid-row">
            <div className="col">
              <Input name="checkout-coupon" value={disabled ? order.coupon_code : couponInput} onChange={setCouponInput} placeholder="Apply a coupon code..." disabled={disabled} error={coupon?.error} />
            </div>
            <div className="col-auto">
              { disabled ?
                <Tippy content="Remove Coupon">
                  <button className="ui-button icon" onClick={resetCouponCode}>
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </Tippy>
              :
                <Tippy content="Apply Coupon">
                  <button className="ui-button icon secondary" onClick={updateCouponCode}>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </button>
                </Tippy>
              }
            </div>
          </div>
        : null }
        <div className="summary-info">
          { costs.map(row => (
            <div key={row.name} className={`cost-row cost-${row.type}`}>
              <div className="label">
                { (() => {
                    if (row.type === 'tax' && row.caSalesTax) {
                      return(
                        <Tippy content="Regardless of your shipping address, this transaction occurs at our business in California, and all California sales require California sales tax.">
                          <span>{row.name}</span>
                        </Tippy>
                      )
                    } else if (row.type === 'shipping' && row.withLockerShipping) {
                      return(
                        <Tippy content="Lockers allow you to purchase smaller quantities of wine and store it with us until your Locker is completely full. If you ship a Locker that is not full yet, you will be charged the normal ground shipping amount.">
                          <span>{row.name}</span>
                        </Tippy>
                      )
                    } else {
                      return(
                      <span>{row.name}</span>
                      )
                    }
                  })()
                }
              </div>
              <div className="amount">${row.value.str}</div>
            </div>
          )) }
        </div>
      </div>

      <div className="panel-body muted payment-method">
        <div className="grid-row">
          <div className="col">
            <div className="label">Payment Method</div>
          </div>
          <div className="col-auto">
            { order.selected_card ? (
              <div key={order.selected_card.brand} className={`payment-info cc-${order.selected_card.brand}`}>
                <i className={`${order.selected_card.brand_fa}`}></i>
                { order.selected_card.last4 }
              </div>
            ) : (
              <div className="payment-info unknown">
                <FontAwesomeIcon icon={faQuestionCircle} />
                ----
              </div>
            ) }
          </div>
        </div>
      </div>
    </>
  );
}

const CheckoutPaymentTotals = (props: { ctx: ReturnType<typeof useCheckout>, costs: CostSummaryRow[], savings: Savings, readonly: boolean }) => {
  const { ctx, costs, savings, readonly } = props;
  const [state, { completeCheckout }] = ctx;
  const { order } = state;

  const totalCost = costs.find(c => c.type === 'total');

  if (readonly) return (
    <div className="panel-footer payment-totals readonly">
      <div className="grid-row">
        <div className="col">
          <div className="totals">
            <div className="amount">${totalCost?.value.str}</div>
            <div className="label">Grand Total</div>
          </div>
        </div>
        <div className="col-auto">
          { order.selected_card ? (
            <div key={order.selected_card.brand} className={`payment-info cc-${order.selected_card.brand}`}>
              <i className={order.selected_card.brand_fa}></i>
              { order.selected_card.last4 }
            </div>
          ) : (
            <div className="payment-info unknown">
              <FontAwesomeIcon icon={faQuestionCircle} />
              ----
            </div>
          ) }
        </div>
      </div>
    </div>
  );

  let disabled = (
    (!state.cart?.items) ||
    (state.cart.items.length === 0) ||
    (order.has_shipping && !order.selected_address) ||
    (!order.selected_card)
  );

  disabled = order.loading || !order.is_valid || false;

  const savingsTooltipRows = savings.breakdown.flatMap((item, index) => {
    const row = `${item.name}$${item.value.str}`
    return index < savings.breakdown.length - 1 ? [row, <br />] : row
  })

  return (
    <div className="panel-footer payment-totals">
      <div className="grid-row">
        <div className="col-12 col-sm-auto order-sm-2">
          <div className="checkout">
            <button className={`ui-button block ${ order.loading ? 'loading' : '' }`} disabled={disabled} onClick={completeCheckout}>Place Order</button>
          </div>
        </div>
        <div className="col-12 col-sm order-sm-1">
          <div className="totals">
            <div className="amount">${totalCost?.value.str}</div>
            <div className="label">Grand Total</div>
            {savings.total.num > 0 &&
              <Tippy content={savingsTooltipRows}>
                <div className="label savings">You Save ${savings.total.str}</div>
              </Tippy>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

const CheckoutSummary = (props: { readonly?: boolean }) => {
  const readonly = props.readonly === true;
  const ctx = useContext(CheckoutContext);
  const [state, {}] = ctx;

  const { costs, savings } = generateCostSummary(readonly ? (state.post_purchase?.purchase || {}) : state.preview);
  const loading = state.cart.loading || state.user.loading || state.preview.loading;

  if (state.order.cart_empty) return (
    <div className="ui-panel checkout-summary-panel empty">
      <div className="panel-header">Summary</div>
      <CheckoutPurchaseSummary ctx={ctx} readonly={readonly} />
      <CheckoutPaymentTotals ctx={ctx} costs={costs} savings={savings} readonly={readonly} />
    </div>
  );

  return (
    <>
      <div className={`ui-panel checkout-summary-panel ${ loading ? 'loading' : '' }`}>
        <div className="panel-header">Summary</div>

        <div className="panel-scrollarea">
          <CheckoutPurchaseSummary ctx={ctx} readonly={readonly} />
          <CheckoutShippingSummary ctx={ctx} />
          <CheckoutPaymentSummary ctx={ctx} costs={costs} readonly={readonly} />

          { state.order.errors && state.order.errors.length > 0 ?
            <div className="panel-body ui-message error">
              <p>Please resolve the following errors:</p>
              <ul>
                { state.order.errors.map((err, i) => (
                  <li key={i}>{err}</li>
                )) }
              </ul>
            </div>
          : null }
        </div>

        <CheckoutPaymentTotals ctx={ctx} costs={costs} savings={savings} readonly={readonly} />
      </div>
    </>
  );
};

export default CheckoutSummary;
