import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle, faEdit } from '@fortawesome/free-solid-svg-icons';

import { formatCityStateZip, formatStreet } from 'javascripts/utils/address';

export type PickupAddressPanelProps = {
  index: number
  address: AddressData;
  selected: boolean;
  setSelectedAddressIndex: (index: number | null) => void;
  onEdit?: () => void;
};

const PickupAddressPanel = (props: PickupAddressPanelProps) => {
  const { index, address, selected, setSelectedAddressIndex, onEdit } = props;

  const onSelect = () => {
    if (selected) {
      setSelectedAddressIndex(null)
    } else {
      setSelectedAddressIndex(index)
    }
  }

  const editAddress = async () => {
    if (onEdit) onEdit();
  }

  return (
    <label className={`ui-selection-panel address ${ selected ? 'selected' : 'muted' }`}>
      <input type="checkbox" name="payment-method" checked={selected} onChange={onSelect} className="selection-checkbox" />
      <div className="checkbox unchecked"><FontAwesomeIcon icon={faCircle} /></div>
      <div className="checkbox checked"><FontAwesomeIcon icon={faCheckCircle} /></div>
      <div className="title">
        {address.company}
      </div>
      <div className="content">
        { selected ? (
          <>
            {formatStreet(address)}<br />
            {formatCityStateZip(address)}<br />
            {address.phone ? <div className="phone">{address.phone}</div> : null }
            <div className="preferred-shipping-carrier">{address.preferred_shipping_carrier.name} preferred</div>
          </>
        ) : (
          <>
            {formatStreet(address)}, {formatCityStateZip(address)}
          </>
        ) }
      </div>

      <div className="panel-actions">
        <button type="button" className="panel-action" onClick={editAddress}>
          Use Address
          <span className="icon">
            <FontAwesomeIcon icon={faEdit} />
          </span>
        </button>
      </div>
    </label>
  );
}

export default PickupAddressPanel;
