import * as React from 'react';
import { useContext, useCallback, useEffect } from 'react';

import { subscribe, unsubscribe, publish } from 'javascripts/utils/events';
import { CheckoutContext } from './CheckoutPage';
import AddressPanel from './AddressPanel';
import CheckoutTempControl from './CheckoutTempControl';

const CheckoutShippingPanel = () => {
  const [state, { setShippingAddress, setIcePacks }] = useContext(CheckoutContext);

  useEffect(() => {
    publish('CHECKOUT_ADDRESS_SELECTION_UPDATED', state.order.selected_address);
    if(!state.order.selected_address?.allow_ice_packs) setIcePacks(false);
  }, [state.order.selected_address]);

  // subscribe to external address selection requests
  useEffect(() => {
    const token = subscribe('CHECKOUT_ADDRESS_SELECTION_REQUESTED', (address) => setShippingAddress(address));
    return () => unsubscribe(token);
  }, [setShippingAddress]);

  const showAddressModal = useCallback(() => {
    publish('CHECKOUT_ADDRESS_SELECTION_UPDATED', state.order.selected_address);
    publish('OPEN_MODAL', 'address-management');
  }, [state.user.addresses, state.order.selected_address]);

  let addresses: React.ReactFragment[] = [];

  const savedAddresses = state.user.addresses || [];
  for (const address of savedAddresses) {
    const selected = state.order.selected_address?.id === address.id;
    const addressPanel = <AddressPanel key={address.id} address={address} selected={selected} showPreferred setShippingAddress={setShippingAddress} />;

    if (selected) addresses = [addressPanel, ...addresses];
    else addresses.push(addressPanel);
  }

  // display only 3 entries
  addresses = addresses.slice(0, 3);

  const empty = addresses.length === 0;
  if (empty) {
    addresses.push(
      <div key="empty" className="selection-empty">No saved addresses yet.</div>
    );
  }

  return (
    <div className="ui-panel checkout-panel checkout-shipping">
      <div className="panel-header">
        <h2>Where is this order being sent?</h2>
      </div>
      <div className="panel-body">
        <div className="grid-row">
          <div className="col-12 col-xl-6">
            <div className="ui-selection-list">
              {addresses}
            </div>
            <button className="ui-button small" onClick={showAddressModal}>{ empty ? 'Add a New Address' : 'Manage Addresses' }</button>
          </div>
          <div className="col-12 col-xl-6">
            <CheckoutTempControl />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutShippingPanel;
