import * as React from 'react';
import { useContext, useCallback, useEffect } from 'react';

import { subscribe, publish, unsubscribe } from 'javascripts/utils/events';
import { CheckoutContext } from './CheckoutPage';
import CreditCardPanel from './CreditCardPanel';

const CheckoutPaymentPanel = () => {
  const [state, { setPaymentMethod }] = useContext(CheckoutContext);

  useEffect(() => {
    publish('CHECKOUT_CREDIT_CARD_SELECTION_UPDATED', state.order.selected_card);
  }, [state.order.selected_card]);

  // subscribe to external card selection requests
  useEffect(() => {
    const token = subscribe('CHECKOUT_CREDIT_CARD_SELECTION_REQUESTED', (card) => setPaymentMethod(card));
    return () => unsubscribe(token);
  }, [setPaymentMethod]);

  const showCcModal = useCallback(() => {
    publish('CHECKOUT_CREDIT_CARD_SELECTION_UPDATED', state.order.selected_card);
    publish('OPEN_MODAL', 'cc-management');
  }, [state.user.cards, state.order.selected_card]);

  let paymentCards: React.ReactFragment[] = [];
  let savedCards = state.user.cards || [];
  for (const card of savedCards) {
    const selected = state.order.selected_card?.id === card.id;
    const cardPanel = <CreditCardPanel key={card.id} card={card} selected={selected} showPreferred setPaymentMethod={setPaymentMethod} />;

    if (selected) paymentCards = [cardPanel, ...paymentCards];
    else paymentCards.push(cardPanel);
  }

  // display only 3 entries
  paymentCards = paymentCards.slice(0, 3);

  const empty = paymentCards.length === 0;
  if (empty) {
    paymentCards.push(
      <div key="empty" className="selection-empty">No saved credit cards yet.</div>
    );
  }

  return (
    <div className="ui-panel checkout-panel checkout-payment">
      <div className="panel-header">
        <h2>How is the order being paid for?</h2>
      </div>
      <div className="panel-body">
        <div className="grid-row">
          <div className="col-12 col-lg-6">
            <div className="ui-selection-list">
              {paymentCards}
            </div>
            <button className="ui-button small" onClick={showCcModal}>{ empty ? 'Add a New Credit Card' : 'Manage Credit Cards' }</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPaymentPanel;
