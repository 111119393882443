import * as React from 'react';
import { useRef, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import AddressCandidatePanel, { ReferenceAddressData } from './AddressCandidatePanel';
import { publish } from 'javascripts/utils/events';

const VerifyAddressModal = () => {
  const [referenceAddress, setReferenceAddress] = useState<ReferenceAddressData>();
  const [addressCandidates, setAddressCandidates] = useState<AddressData[] | null>(null);
  const [selectedAddressIndex, setSelectedAddressIndex] = useState<number | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const isEmpty = addressCandidates?.length === 0;

  const closeModal = () => {
    publish('CLOSE_MODAL', 'verify-address');
    timeoutRef.current = setTimeout(() => {
      setReferenceAddress(undefined);
      setAddressCandidates(null);
      setSelectedAddressIndex(null);
    }, 1000);
  }

  const onSelectedAddressIndexChange = (index: number | null) => {
    setSelectedAddressIndex(index)
  }

  const onAccept = () => {
    const address = addressCandidates?.[selectedAddressIndex!];
    if (!address) return;

    const root = document.querySelector("[data-modal=address-management]");
    if (!root) return console.error("Unable to find modal 'address-management'.");

    const addressCandidateHolder = root.querySelector('#address-candidate-holder');
    if (addressCandidateHolder) {
      addressCandidateHolder.setAttribute('data-address-candidate', JSON.stringify(address));
      const event = new CustomEvent('addressCandidateChosen');
      addressCandidateHolder.dispatchEvent(event);
    }

    closeModal();
  }

  const onKeep = () => {
    const root = document.querySelector("[data-modal=address-management]");
    if (!root) return console.error("Unable to find modal 'address-management'.");

    const event = new CustomEvent('keepAddressAsEntered');
    root.dispatchEvent(event);

    closeModal();
  }

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const addressHolder = document.getElementById('address-holder');
    if (addressHolder) {
      const extractAddressValues = () => {
        const data = addressHolder.getAttribute('data-reference-address');
        if (data) {
          setReferenceAddress(JSON.parse(data));
          addressHolder.removeAttribute('data-reference-address');
        }
      };

      extractAddressValues();

      addressHolder.addEventListener('addressInjected', extractAddressValues);

      return () => {
        addressHolder.removeEventListener('addressInjected', extractAddressValues);
      };
    }
  }, []);

  useEffect(() => {
    const addressHolder = document.getElementById('address-holder');
    if (addressHolder) {
      const handleAddressCandidatesInjected = () => {
        const data = addressHolder.getAttribute('data-address-candidates');
        if (data) {
          setAddressCandidates(JSON.parse(data));
          addressHolder.removeAttribute('data-address-candidates');
        }
      };

      addressHolder.addEventListener('addressCandidatesInjected', handleAddressCandidatesInjected);

      return () => {
        addressHolder.removeEventListener('addressCandidatesInjected', handleAddressCandidatesInjected);
      };
    }
  }, []);

  const isAddressSame = (address1: any, address2: any) => {
    const isStreet2Equal = (address1?.street2 ?? '').toLowerCase() === (address2?.street2 ?? '').toLowerCase();

    return (
      address1.street1.toLowerCase() === address2.street1.toLowerCase() &&
      isStreet2Equal &&
      address1.city.toLowerCase() === address2.city.toLowerCase() &&
      address1.state.toLowerCase() === address2.state.toLowerCase() &&
      address1.zip === address2.zip
    );
  };

  let addresses = null;
  addresses = addressCandidates?.map((address, index) => {
    const selected = selectedAddressIndex === index;
    return (
      <AddressCandidatePanel key={index} index={index} referenceAddress={referenceAddress} address={address} selected={selected} setSelectedAddressIndex={onSelectedAddressIndexChange} onAccept={onAccept} />
    );
  });

  useEffect(() => {
    if (addressCandidates?.length === 1 && referenceAddress) {
      const address = addressCandidates[0];
      if (isAddressSame(address, referenceAddress)) {
        onKeep();
      }
    }
  }, [addressCandidates, referenceAddress]);

  return (
    <div className="left-column">
      <div className="modal-header sticky">
        <div className="title">
          Verify Address
        </div>
        <button className="modal-toggle" data-modal-toggle onClick={closeModal}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
      <div className="modal-body">
        <div className='address-container'>
          <div className='verify-info'>Please verify your address. You entered:</div>
          <div className='reference-address'>{referenceAddress?.street1}</div>
          <div className='reference-address'>{referenceAddress?.street2}</div>
          <span className='reference-address'>{referenceAddress?.city}</span>
          <span>, </span>
          <span className='reference-address'>{referenceAddress?.state}</span>
          <span> </span>
          <span className='reference-address'>{referenceAddress?.zip}</span>
        </div>
        <div className="ui-selection-list">
          {isEmpty
            ? <div className="ui-message error">We couldn't verify your address.</div>
            : <div className="suggested-message">Review suggested address{addresses && addresses.length > 1 && 'es'}:</div>
          }
          {addresses ?? null}
        </div>
      </div>
      <div className="modal-footer sticky">
        <div className="grid-row">
          <div className="col-auto">
            <button type="button" className="ui-button secondary" onClick={closeModal}>Go Back</button>
          </div>
          <div className="col"></div>
          <div className="col-auto">
            <button type="button" className="ui-button" onClick={onKeep}>Keep as Entered</button>
          </div>
        </div>
      </div>
      <div className='hidden' id='address-holder'></div>
    </div>
  );
};

export default VerifyAddressModal;
