import * as React from 'react';
import { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { CheckoutContext } from './CheckoutPage';
import CartItem from './CartItem';
import { publish } from 'javascripts/utils/events';

// @ts-ignore
import defaultFeaturedImageBg from '../../assets/images/sales/default_featured_image_bg.jpg';

const CheckoutReviewCartPanel = () => {
  const [state, {}] = useContext(CheckoutContext);
  const { cart, preview } = state;

  return (
    <>
      <div className={`ui-panel checkout-panel checkout-review-cart ${ cart.loading ? 'loading' : '' }`}>
        <div className="panel-header">
          <h2>Review Your Cart</h2>
        </div>
        <div className="panel-body">
          <div className="cart-items">
            { cart.items && cart.items.length > 0 ? cart.items.map(
              item => (<CartItem key={item.sale.id} item={item} editable />)
            ) : (
              <div className="cart-empty">
                Your cart is currently empty.
              </div>
            ) }
          </div>
        </div>
      </div>

      {/* vault upsell panel */}
      {/* Disable Vault Program */}
      { /* state.order.order_type === 'locker_order' && !state.order.locker_only ?
        <div className="ui-panel checkout-panel checkout-vault-upsell">
          <div className="panel-body">
            <div className="grid-row">
              <div className="col-12 col-md">
                <div className="styled-content">
                  <h2>Add Vault Bottles to Your Locker Order</h2>
                  <p>
                    The Vault is a fun way to discover new wine.
                    Add up to 1 Vault bottle per Locker and get a random bottle from our curated list.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-auto">
                <div className="ui-button-group">
                  <button className="ui-button" data-modal-toggle="vault-sales">
                    <span className="text">Available Vault Sales</span>
                    <span className="icon"><FontAwesomeIcon icon={faArrowRight} /></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      : null */ }
    </>
  );
};

export default CheckoutReviewCartPanel;
