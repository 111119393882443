import { useCallback, useEffect, useReducer } from 'react';
import { isEqual } from 'lodash-es';

import useCart from './useCart';
import useCheckoutUserDetails from './useCheckoutUserDetails';
import useCheckoutPreview from './useCheckoutPreview';
import checkoutService from 'javascripts/services/checkout-service';
import { publish, subscribe, unsubscribe } from './events';
import LockerDetailsModal from 'components/LockerDetailsModal';

const validateCheckoutState = (state: CheckoutState) => {
  const { cart, user, order, preview } = state;

  let noLockers = user?.lockers?.length === 0 && order.locker_only

  order.cart_empty = (noLockers || !order.locker_only) && cart?.items?.length === 0;

  order.has_lockers_for_shipping = false;
  order.has_incomplete_lockers_for_shipping = false;
  if (order.order_type === 'locker_order') {
    const indices = order.lockers_for_shipping_index || [];
    order.has_lockers_for_shipping = indices.length > 0;

    for (const i of indices) {
      const locker = preview.lockers ? preview.lockers[i] : null;
      if (!locker) return;
      if (!locker.full) order.has_incomplete_lockers_for_shipping = true;
    }
  }

  // determine if we need to require a shipping address
  order.has_shipping = (
    !user.at_cost && (
      order.order_type === 'single_order' ||
      order.has_lockers_for_shipping
    ) || (
      (order.order_type === 'single_order' && order.at_cost_shipping) ||
      order.has_lockers_for_shipping
    )
  );

  // validate temp control states
  const address = order.selected_address;
  if (address) {
    const available = address.zone_skipping ? address.zone_skipping !== 'forbidden' : false;
    const mandatory = address.zone_skipping === 'required';

    let zone_skipped = false;
    if (available) zone_skipped = mandatory ? true : (order.zone_skipped || false);
    order.zone_skipped = zone_skipped;

    let ice_packs = order.ice_packs || false;
    if (available && !zone_skipped) ice_packs = false;
    order.ice_packs = ice_packs;
  }

  // validate entire object for order completeness
  order.is_valid = true;
  if (
    // no order type selected
    !order.order_type ||
    // cart is empty
    (!order.locker_only && order.cart_empty) ||
    // no selected card
    !order.selected_card ||
    // shipping is required but no address is selected
    (order.has_shipping && !order.selected_address) ||
    // locker-only order but no locker is selected for shipping
    (
      order.locker_only &&
      (!order.lockers_for_shipping_index || order.lockers_for_shipping_index.length === 0)
    ) ||
    // locker-only order but no active lockers
    noLockers
  ) {
    order.is_valid = false;
  }
}

/**
 * Global checkout page reducer.
 */
export const checkoutReducer = (oldState: CheckoutState, action: CheckoutEventData): CheckoutState => {
  let state: CheckoutState = { ...oldState };

  try {
    // ---- handle dispatched actions --------------------------------------- //
    switch (action.type) {
      case 'SET_STATE':
        state = { ...oldState, ...action.state };
        break;

      case 'SET_CART':
        state.cart = action.cart;
        break;

      case 'INITIALIZE_USER_DETAILS':
        // create a copy of the first user_details loaded into the order
        state.order.settings = action.user_details.settings;

        // set address
        if (action.user_details.settings.preferred_shipping_address) {
          state.order.selected_address = action.user_details.settings.preferred_shipping_address;
        } else if (action.user_details.addresses.length === 1) {
          state.order.selected_address = action.user_details.addresses[0];
        }

        // set credit card
        if (action.user_details.settings.preferred_saved_credit_card) {
          state.order.selected_card = action.user_details.settings.preferred_saved_credit_card;
        } else if (action.user_details.cards.length === 1) {
          state.order.selected_card = action.user_details.cards[0];
        }

        // set order preferences
        if (action.user_details.settings.preferred_order_type) {
          state.order.order_type = action.user_details.settings.preferred_order_type;
        }
        if (state.order.settings.always_temp_controlled) {
          state.order.zone_skipped = true;
        }

        // set ice packs
        if (state.order.settings.always_ice_packs) {
          if(state.order.selected_address?.allow_ice_packs) state.order.ice_packs = true;
        }
        if (state.user.at_cost && state.order.settings.always_at_cost_shipping) {
          state.order.at_cost_shipping = true;
        }

        break;

      case 'SET_USER_DETAILS':
        state.user = action.user_details;
        break;

      case 'TOGGLE_INSTANT_CHECKOUT':
        if (state.order.settings) {
          state.order.settings.instant_checkout_enabled = !state.order.settings.instant_checkout_enabled;
        }
        break;

      case 'SET_PREVIEW':
        state.preview = action.preview;
        break;

      case 'SET_ORDER_TYPE':
        state.order.order_type = action.order_type;
        break;

      case 'SET_CREDIT_CARD':
        state.order.selected_card = action.card;
        break;

      case 'SET_SHIPPING_ADDRESS':
        state.order.selected_address = action.address;
        // re-set zone skipping and ice pack preferences on address switch
        if (state.order.settings?.always_temp_controlled) state.order.zone_skipped = true;
        if (state.order.settings?.always_ice_packs) state.order.ice_packs = true;
        break;

      case 'SET_ZONE_SKIPPED':
        state.order.zone_skipped = action.zone_skipped;
        break;

      case 'SET_SHIPPING_ICE_PACKS':
        state.order.ice_packs = action.ice_packs;
        break;

      case 'SET_SHIP_DATE':
        state.order.ship_on = action.ship_on == null ? undefined : action.ship_on;
        break;

      case 'SET_AT_COST_SHIPPING':
        state.order.at_cost_shipping = action.at_cost_shipping;
        break;

      case 'SET_LOCKER_SHIPPING':
        const indices = state.order?.lockers_for_shipping_index || [];
        const i = indices.indexOf(action.locker_index);
        const shipped = i != undefined && i >= 0;

        if (action.ship && !shipped) indices.push(action.locker_index);
        if (!action.ship && shipped) indices.splice(i, 1);

        state.order.lockers_for_shipping_index = [...indices.sort()];
        state.order.locker_selection_dirty = true;

        break;

      case 'SET_LOCKERS_FOR_SHIPPING':
        state.order.lockers_for_shipping_index = action.locker_indices;

        break;

      case 'SET_COUPON_CODE':
        state.order.coupon_code = action.coupon_code;
        break;

      case 'SET_ORDER_LOADING':
        state.order.loading = action.loading;
        break;

      case 'SET_ORDER_ERRORS':
        state.order.errors = action.errors;
        break;

      case 'ORDER_COMPLETED':
        state.post_purchase = action.post_purchase;
        state.order.complete = true;
        break;

      default:
        throw new Error(`Unhandled action [${action['type']}].`);
    }

    // validate and generate calculated properties
    validateCheckoutState(state);
  } catch (e) {
    console.error('Failed to update checkout state:', e);
  }

  return state;
}

/**
 * Checkout hook that exposes global checkout data along with dispatcher methods.
 */
const useCheckout = (defaultCheckoutData: CheckoutState) => {
  const [checkoutState, dispatch] = useReducer(checkoutReducer, defaultCheckoutData);

  // set up callbacks methods
  const setState = useCallback((state: Partial<CheckoutState>) => dispatch({ type: 'SET_STATE', state }), []);
  const initializeUserDetails = useCallback((user_details: CheckoutUserDetails) => dispatch({ type: 'INITIALIZE_USER_DETAILS', user_details }), []);
  const setOrderType = useCallback((order_type: OrderType) => dispatch({ type: 'SET_ORDER_TYPE', order_type }), []);
  const setPaymentMethod = useCallback((card: CreditCardData) => dispatch({ type: 'SET_CREDIT_CARD', card }), []);
  const setShippingAddress = useCallback((address: AddressData) => dispatch({ type: 'SET_SHIPPING_ADDRESS', address }), []);
  const setZoneSkipped = useCallback((zone_skipped: boolean) => dispatch({ type: 'SET_ZONE_SKIPPED', zone_skipped }), []);
  const setIcePacks = useCallback((ice_packs: boolean) => dispatch({ type: 'SET_SHIPPING_ICE_PACKS', ice_packs }), []);
  const setShipDate = useCallback((ship_on: null | string) => dispatch({ type: 'SET_SHIP_DATE', ship_on }), []);
  const setAtCostShipping = useCallback((at_cost_shipping: boolean) => dispatch({ type: 'SET_AT_COST_SHIPPING', at_cost_shipping }), []);
  const setLockerShipping = useCallback((locker_index: number, ship: boolean) => dispatch({ type: 'SET_LOCKER_SHIPPING', locker_index, ship }), []);
  const setLockersForShipping = useCallback((locker_indices: number[]) => dispatch({ type: 'SET_LOCKERS_FOR_SHIPPING', locker_indices }), []);
  const setCouponCode = useCallback((coupon_code?: string) => dispatch({ type: 'SET_COUPON_CODE', coupon_code }), []);
  const setOrderLoading = useCallback((loading: boolean) => dispatch({ type: 'SET_ORDER_LOADING', loading }), []);
  const setOrderErrors = useCallback((errors: string[]) => dispatch({ type: 'SET_ORDER_ERRORS', errors }), [])
  const setOrderCompleted = useCallback((post_purchase: CheckoutPostPurchase) => dispatch({ type: 'ORDER_COMPLETED', post_purchase }), [])
  const toggleInstantCheckout = useCallback(() => dispatch({ type: 'TOGGLE_INSTANT_CHECKOUT' }), []);

  // handle user settings initialization
  useEffect(() => {
    const token = subscribe('CHECKOUT_USER_DETAILS_INITIALIZED', (details) => {
      initializeUserDetails(details);
    });
    return () => unsubscribe(token);
  }, []);

  // keep cart state up-to-date
  const [cart, {}] = useCart();
  useEffect(() => {
    dispatch({ type: 'SET_CART', cart });
  }, [cart._key]);

  // refresh the cart with a view_upsell:true flag once on page load
  useEffect(() => {
    publish('CART_REFRESH', true);
  }, []);

  // keep user checkout details up-to-date
  const [user, {}] = useCheckoutUserDetails();
  useEffect(() => {
    dispatch({ type: 'SET_USER_DETAILS', user_details: user });
  }, [user._key]);

  // keep user checkout preview up-to-date
  const [preview, { updatePreview }] = useCheckoutPreview();
  useEffect(() => {
    // prevent further changes if order is already complete
    if (checkoutState.order.complete) return;

    dispatch({ type: 'SET_PREVIEW', preview });

    const locker_index = checkoutState.order?.lockers_for_shipping_index || [];
    const lockers = preview?.lockers || [];

    // prevent out of bounds locker index for shipping
    const filtered_lockers = locker_index.filter((index) => Number(index) <= lockers.length - 1);
    if(!checkoutState.cart.loading && filtered_lockers.length != locker_index.length) setLockersForShipping(filtered_lockers);

    // automated locker shipping behavior
    if (!checkoutState.order.locker_selection_dirty) {
      if (checkoutState.order.settings && checkoutState.order.settings.always_ship_full_lockers && lockers) {
        // get IDs of full lockers
        const lockerIds: number[] = [];
        for (let i = 0; i < lockers.length; i++) {
          if (lockers[i].full) lockerIds.push(i);
        }
        if (!isEqual(locker_index, lockerIds)) setLockersForShipping(lockerIds);

      } else if (preview.minimum_lockers_to_ship && lockers) {
        // ship all the minimum lockers by default
        const lockerIds: number[] = [];
        for (let i = 0; i < preview.minimum_lockers_to_ship; i++) {
          lockerIds.push(i);
        }
        if (!isEqual(locker_index, lockerIds)) setLockersForShipping(lockerIds);
      }
    }
  }, [
    preview._key,
    checkoutState.order.settings,
    checkoutState.order.lockers_for_shipping_index,
    checkoutState.order.complete
  ]);

  // update preview when cart, user, or some order selections change
  useEffect(() => {
    const cartData = checkoutState.order.locker_only ? { loading: false, items: [] } : cart;
    updatePreview({ cart: cartData, user, preview, order: checkoutState.order });
    setOrderErrors([]);
  }, [
    updatePreview,
    cart._key,
    user._key,
    checkoutState.order.order_type,
    checkoutState.order.lockers_for_shipping_index,
    checkoutState.order.selected_address,
    checkoutState.order.selected_card,
    checkoutState.order.ice_packs,
    checkoutState.order.zone_skipped,
    checkoutState.order.at_cost_shipping,
    checkoutState.order.coupon_code,
    checkoutState.order.locker_only
  ]);

  const completeCheckout = useCallback(async () => {
    setOrderLoading(true);
    setOrderErrors([]);

    try {
      const cartData = checkoutState.order.locker_only ? { loading: false, items: [] } : cart;
      const response = await checkoutService.completeCheckout({ cart: cartData, user, preview, order: checkoutState.order });
      if (response) {
        setOrderCompleted(response.data);
        publish('CART_REFRESH', true);
        window.localStorage.removeItem('QUERY_STRING_COUPON');

        // scroll to top
        document.querySelector('#content > header')?.scrollIntoView({ behavior: 'smooth' });
      } else {
        setOrderErrors(['Server returned no response while completing request.']);
      }
    } catch(e: any) {
      const errors = e.response?.data?.errors || ['An unexpected error occurred while completing your request.'];
      setOrderErrors(errors);
    }

    setOrderLoading(false);
  }, [
    cart._key,
    user._key,
    preview._key,
    checkoutState.order.order_type,
    checkoutState.order.lockers_for_shipping_index,
    checkoutState.order.selected_address,
    checkoutState.order.selected_card,
    checkoutState.order.ice_packs,
    checkoutState.order.zone_skipped,
    checkoutState.order.at_cost_shipping,
    checkoutState.order.coupon_code,
    checkoutState.order.locker_only
  ]);

  // handle order type limitations
  useEffect(() => {
    let singleOrderUnavailable = false;
    let lockerOrderUnavailable = false;

    // prevent setting order to AddToLocker when magnum products are present
    if (preview.has_magnum_products) {
      lockerOrderUnavailable = true;
    }

    // prevent setting order to SingleOrder when cart contains vault bottles
    if (preview.has_vault_products) {
      singleOrderUnavailable = true;
    }

    // prevent setting order to SingleOrder when locker_only checkout is active
    if (checkoutState.order.locker_only) {
      singleOrderUnavailable = true;
    }

    if (singleOrderUnavailable && lockerOrderUnavailable) {
      // both checkouts are unavailable, we can't proceed with the checkout
      if (checkoutState.order.order_type != null) setOrderType(null);
    } else if (singleOrderUnavailable && checkoutState.order.order_type != 'locker_order') {
      // only locker orders are available
      setOrderType('locker_order');
    } else if (lockerOrderUnavailable && checkoutState.order.order_type != 'single_order') {
      // only single orders are available
      setOrderType('single_order');
    }
  }, [
    checkoutState.order.order_type,
    preview.has_magnum_products,
    preview.has_vault_products,
    checkoutState.order.locker_only
  ]);

  // handle automatic instant checkout toggling cases
  useEffect(() => {
    let preventInstantCheckout = false;

    // prevent instant checkout when locker_only checkout is active
    if (checkoutState.order.locker_only) preventInstantCheckout = true;

    // prevent instant checkout when both order types are invalid
    if (checkoutState.order.order_type === null) preventInstantCheckout = true;

    if (preventInstantCheckout && checkoutState.order.settings?.instant_checkout_enabled) toggleInstantCheckout();
  }, [
    checkoutState.order.locker_only,
    checkoutState.order.order_type,
    checkoutState.order.settings?.instant_checkout_enabled
  ]);

  // autonomously reduce the cart vault contents if it exceeds max amount
  useEffect(() => {
    if (checkoutState.preview.valid_vault) return;
    if (checkoutState.cart.loading || checkoutState.preview.loading || !checkoutState.preview.lockers) return;

    /**
     * Note: this vault count eligibility calculation only works because of the assumption that
     * valid_vault can only be false if the number of vaults already exceed allowable vault bottles
     * for the current.
     *
     * This means that this will not work for scenarios where lockers are eligible for a vault but
     * has no vault bottle yet.
     */
    if (!checkoutState.preview.valid_vault) {
      const vaultItems = checkoutState.cart.items?.filter(i => i.sale.product.is_vault) || [];
      const vaultTotal = vaultItems.reduce((sum, i) => sum+i.qty, 0);
      const eligibleVaultCount = checkoutState.preview.lockers
        ?.filter(l => l.line_items
          .find(i => i.sale.product.is_vault && i.new)
        ).length || 0;
      if (vaultItems.length > 0 && vaultTotal > eligibleVaultCount) {
        // just get the first vault item to modify
        const item = vaultItems[0];
        publish('CART_UPDATE_REQUESTED', {
          saleId: item.sale.id,
          quantity: item.qty - (vaultTotal - eligibleVaultCount)
        });
      }
    }
  }, [
    checkoutState.cart._key,
    checkoutState.preview.valid_vault,
    checkoutState.preview.lockers
  ]);

  // hook outputs
  const cartData: Loadable<CartData> = checkoutState.order.locker_only ? { loading: false, items: [] } : cart;
  const state = { ...checkoutState, cart: cartData };
  const actions = {
    setState, setOrderType, setPaymentMethod,
    setShippingAddress, setZoneSkipped, setIcePacks,
    setShipDate, setAtCostShipping, toggleInstantCheckout,
    setLockerShipping, setCouponCode,
    updatePreview, completeCheckout, setOrderErrors
  };
  return [state, actions] as [state: typeof state, actions: typeof actions];
}

export default useCheckout;
