import * as React from 'react';
import { useContext } from 'react';

import { CheckoutContext } from './CheckoutPage';
import CartItem from './CartItem';
import CheckoutLockersPanel from './CheckoutLockersPanel';
import AddressPanel from './AddressPanel';
import CreditCardPanel from './CreditCardPanel';
import CheckoutTempControl from './CheckoutTempControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { publish } from 'javascripts/utils/events';
import CheckoutModePanel from './CheckoutModePanel';

// @ts-ignore
import defaultFeaturedImageBg from '../../assets/images/sales/default_featured_image_bg.jpg';

const InstantCheckoutPanel = () => {
  const [state, { toggleInstantCheckout }] = useContext(CheckoutContext);
  const { cart, user, preview } = state;
  const loading = cart.loading || user.loading || preview.loading;

  return (
    <>
      <div className={`ui-panel checkout-panel instant-checkout ${ loading ? 'loading' : '' }`}>
        <div className="panel-body">
          <div className="cart-label">You Ordered</div>

          <div className="cart-items">
            { cart.items && cart.items.length > 0 ? cart.items.map(
              item => (<CartItem key={item.sale.id} item={item} editable />)
            ) : (
              <div className="cart-empty">
                Your cart is currently empty.
              </div>
            ) }
          </div>
        </div>

        <div className="panel-footer">
          <div className="instant-checkout-header">
            <div className="grid-row">
              <div className="col">
                <h2>Instant Checkout</h2>
                <div className="what-is-instant-checkout-toggle" data-modal-toggle="what-is-instant-checkout">
                  What's this? <FontAwesomeIcon icon={faQuestionCircle} />
                </div>
              </div>
              <div className="col-auto">
                <button className="ui-button h-to-xs" onClick={() => toggleInstantCheckout()}>
                  Edit Order Details
                </button>
                <button className="ui-button icon h-from-sm" onClick={() => toggleInstantCheckout()}>
                  <FontAwesomeIcon icon={faEdit} />
                </button>
              </div>
            </div>
          </div>

          <div className="instant-checkout-details">
            <CheckoutModePanel readonly />

            { state.order.order_type === 'locker_order' ?
              <CheckoutLockersPanel instantCheckout />
            : null }

            <div className="grid-row">
              <div className="col-12 col-lg-6">
                <div className="section-label">Shipping</div>
                { !state.order.has_shipping ?
                  <>
                    <p className="muted">No items will be shipped for this order.</p>
                  </>
                : state.order.selected_address ?
                  <>
                    <AddressPanel address={state.order.selected_address} selected />
                    <CheckoutTempControl readonly />
                  </>
                : null }
              </div>
              <div className="col-12 col-lg-6">
                <div className="section-label">Payment</div>
                { state.order.selected_card ?
                  <CreditCardPanel card={state.order.selected_card} selected />
                : null }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InstantCheckoutPanel;
