import * as React from 'react';
import { useState, useEffect, useCallback, useLayoutEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { publish } from 'javascripts/utils/events';
import PickupAddressPanel from './PickupAddressPanel';
import Input from './Input';
import Select from './Select';
import userService from 'javascripts/services/user-service';
import AddressForm from './AddressForm';

const carrierHoldLocationFormSchema = yup.object().shape({
  city: yup.string().trim(),
  state: yup.string().required('This field is required.'),
  zip: yup.string()
    .test('zip', 'ZIP Code must be a 5-digit number.', (zip) => {
      if (!zip) return true;
      return /^\d{5}$/.test(zip);
    }),
  preferred_shipping_carrier_id: yup.number().required('Please select a preferred shipping carrier.')
});

const defaultState = userService.getDefaultState();
const defaultShippingCarrier = userService.getDefaultShippingCarrier(defaultState) || 1;
const defaultValues = {
  state: defaultState,
  preferred_shipping_carrier_id: defaultShippingCarrier
};

const CarrierHoldLocationsModal = () => {
  const [carrierHoldLocations, setCarrierHoldLocations] = useState<AddressData[]>([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [selectedAddressIndex, setSelectedAddressIndex] = useState<number | null>(null);
  const [editAddress, setEditAddress] = useState<AddressData | null>(null);
  const [createMode, setCreateMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [serverErrors, setServerErrors] = useState<string[]>([]);
  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm<AddressData>({
    defaultValues: defaultValues,
    resolver: yupResolver(carrierHoldLocationFormSchema)
  });

  const watchState = watch('state');
  const previousWatchState = React.useRef(watchState);
  const states = userService.getStateOptions();
  const shippingCarriers = userService.getShippingCarrierOptions(watchState);
  const allowedCarriers = ['UPS', 'FedEx'];
  const shippingCarrierOptions = shippingCarriers.filter(shippingCarrier => allowedCarriers.includes(shippingCarrier.text));

  const leftColumn = React.useRef<HTMLDivElement>(null);

  const handleOnEdit = useCallback((address: AddressData) => {
    setCreateMode(false);
    setEditAddress(address);
  }, []);

  const handleCancel = useCallback(() => {
    setEditAddress(null);
  }, []);

  const closeModal = () => {
    publish('OPEN_MODAL', 'address-management');
    setCarrierHoldLocations([]);
    publish('CLOSE_MODAL', 'carrier-hold-locations');
  }

  const onSelectedAddressIndexChange = (index: number | null) => {
    handleCancel()
    setSelectedAddressIndex(index)
  }

  // reset shipping carrier when state changes
  useEffect(() => {
    if (previousWatchState.current !== watchState) {
        const shippingCarrier = userService.getDefaultShippingCarrier(watchState);
        if (shippingCarrier) setValue('preferred_shipping_carrier_id', shippingCarrier);
        previousWatchState.current = watchState;
      }
    }, [watchState, shippingCarriers]);

  const onSubmit = async (location: LocationData) => {
    setLoading(true);
    setServerErrors([]);
    const response = await userService.getCarrierHoldLocations(location);
    if (response.result === 'error') {
      setServerErrors(response.errors);
    } else {
      setServerErrors([]);
      setCarrierHoldLocations(response.addresses || []);
      if (response.addresses?.length === 0) {
        setIsEmpty(true);
      } else {
        setIsEmpty(false);
      }
    }
    setLoading(false);
  }

  // handle automatic modal resizing
  useLayoutEffect(() => {
    if (!leftColumn.current) return;

    const modalRoot = leftColumn.current.closest('.ui-modal-root');
    if (!modalRoot) return;

    modalRoot.classList.remove('modal-xs');
    modalRoot.classList.remove('modal-sm');
    modalRoot.classList.remove('modal-md');
    modalRoot.classList.remove('modal-lg');
    modalRoot.classList.remove('modal-xl');

    if (editAddress || createMode) {
      modalRoot.classList.add('modal-xl');
      modalRoot.classList.add('edit-form-active');
    } else {
      modalRoot.classList.add('modal-sm');
      modalRoot.classList.remove('edit-form-active');
    }
  }, [editAddress, createMode, carrierHoldLocations]);

  // build list of address panels
  var addresses = [];
  addresses = carrierHoldLocations.map((address, index) => {
    const selected = selectedAddressIndex === index;
    return (
      <PickupAddressPanel key={index} index={index} address={address} selected={selected} setSelectedAddressIndex={onSelectedAddressIndexChange} onEdit={() => handleOnEdit(address)} />
    );
  });

  return (
    <>
      <div className="left-column" ref={leftColumn}>
        <form className='search-form' onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-header sticky">
            <div className="title">
              Pickup Address
            </div>
            <button className="modal-toggle" data-modal-toggle onClick={closeModal}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className="modal-body">
            <div className="ui-form">
              <div className="grid-row">
                <div className="col-12 col-sm-6">
                  <Input label="City" id="address-city" name="city" register={register} error={errors.city?.message} minLength={3} maxLength={50} />
                </div>
                <div className="col-12 col-sm-6">
                  <Select options={states} label="State" id="address-state" name="state" register={register} error={errors.state?.message} />
                </div>
              </div>
              <div className="grid-row">
                <div className="col-12 col-sm-6">
                  <Input label="ZIP Code" id="address-zip-code" name="zip" register={register} error={errors.zip?.message} placeholder="00000" maxLength={5} />
                </div>
                <div className="col-12 col-sm-6">
                  <Select options={shippingCarrierOptions} label="Preferred Carrier" id="address-shipping-carrier" name="preferred_shipping_carrier_id" register={register} error={errors.preferred_shipping_carrier_id?.message} />
                </div>
              </div>

              { serverErrors.length > 0 ?
                <div className="ui-message error">
                  <p>Please resolve the following errors:</p>
                  <ul>
                    { serverErrors.map(error => (
                      <li key={error}>
                        <strong>{error}</strong>
                      </li>
                    )) }
                  </ul>
                </div>
              : null }
            </div>
            <div className="ui-selection-list">
              { isEmpty ? <div className="ui-message error">No locations found.</div> : null }
              { addresses }
            </div>
          </div>
          <div className="modal-footer sticky">
            <div className="grid-row">
              <div className="col-auto">
                <button type="button" className="ui-button secondary" onClick={closeModal}>Cancel</button>
              </div>
              <div className="col"></div>
              <div className="col-auto">
                <button type="submit" className={`ui-button ${ loading ? 'loading' : '' }`}>
                  <span className="text">Search</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="right-column">
        { editAddress
          ? <div key={'new'}>
              <AddressForm
                address={editAddress}
                onCreateCancelled={handleCancel}
              />
            </div>
          : null
        }
      </div>
    </>
  );
};

export default CarrierHoldLocationsModal;
