import { useCallback, useEffect, useState } from 'react';

import checkoutService from '../services/checkout-service';
import { publish, subscribe, unsubscribe } from './events';

let prev: any = {};

/**
 * Checkout hook that allows read-write access to checkout options fetched from /checkout/user_details.
 */
const useCheckoutUserDetails = () => {
  const [userDetails, setUserDetails] = useState<Loadable<CheckoutUserDetails>>({
    initialized: false,
    loading: true
  });

  // helper methods
  const updateUserDetails = useCallback(async () => {
    setUserDetails(oldState => ({ ...oldState, _key: 0, loading: true }));
    const newDetails = await checkoutService.getUserDetails();
    if (!userDetails.initialized) publish('CHECKOUT_USER_DETAILS_INITIALIZED', newDetails);
    setUserDetails({ ...newDetails, initialized: true, loading: false });
  }, [userDetails.initialized]);

  prev = userDetails;

  // event subscription
  useEffect(() => {
    const token = subscribe('CHECKOUT_UPDATE_USER_DETAILS', () => updateUserDetails());
    return () => unsubscribe(token);
  }, [updateUserDetails]);

  // publish address updates to global event space
  useEffect(() => {
    publish('CHECKOUT_ADDRESSES_UPDATED', userDetails.addresses);
  }, [userDetails.addresses]);

  // publish card updates to global event space
  useEffect(() => {
    publish('CHECKOUT_CREDIT_CARDS_UPDATED', userDetails.cards);
  }, [userDetails.cards]);

  // publish address updates to global event space
  useEffect(() => {
    publish('CHECKOUT_ADDRESSES_UPDATED', userDetails.addresses);
  }, [userDetails.addresses]);

  // load checkout settings on first render
  useEffect(() => {
    updateUserDetails();
  }, []);


  // hook outputs
  const state: Loadable<CheckoutUserDetails> = userDetails;
  const actions = { updateUserDetails };
  return [state, actions] as [state: Loadable<CheckoutUserDetails>, actions: typeof actions];
}

export default useCheckoutUserDetails;
