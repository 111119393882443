import * as React from 'react';
import classNames from 'classnames';
import { UseFormRegister } from 'react-hook-form';

const chevronDown: string = require('../../assets/images/icons/chevron-down.svg');

export type SelectOption = {
  value: string;
  text: string;
}

export type SelectProps = {
  label?: string;
  id?: string;
  name: string;
  placeholder?: string;
  disabled?: boolean;
  error?: string;
  options: SelectOption[];

  // option 1 (uncontrolled): specify a react-hook-form register
  register?: UseFormRegister<any>;

  // option 2 (controlled): specify value and change handler
  value?: string;
  onChange?: (value: string) => void;
};

const Select = (props: SelectProps) => {
  const { label, id, name, value, placeholder, register, disabled, error } = props;

  const inputId = id || name;

  const onChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    if (props.onChange) props.onChange(event.target.value);
  }

  const options = props.options.map(o => (
    <option key={o.value} value={o.value}>{o.text}</option>
  ));

  let select;
  if (register) {
    select = (
      <select id={inputId} disabled={disabled} placeholder={placeholder} {...register(name)}>
        {options}
      </select>
    );
  } else {
    select = (
      <select id={inputId} name={name} value={value == undefined ? '' : value} onChange={onChange} disabled={disabled} placeholder={placeholder}>
        {options}
      </select>
    );
  }

  return (
    <div className={classNames('ui-select', { 'has-error': error })}>
      { label ? <label htmlFor={inputId}>{label}</label> : null }
      { select }
      <div className="input-icon right">
        <img src={chevronDown} />
      </div>
      { error ? <div className="input-error">{ error }</div> : null }
    </div>
  );
}

export default Select;
