import * as React from 'react';

const CheckoutEmptyPanel = () => {
  return (
    <div className="ui-panel checkout-panel checkout-empty">
      <div className="panel-body">
        <h2>Your cart is empty!</h2>
        <p>
          Greetings, Operative! It's quite lonely in here. Feel free check out <a href="/">today's featured wine</a> or peruse the <a href="/store">store</a> if you're looking for inspiration.
        </p>
        <div className="ui-button-group">
          <a className="ui-button" href="/">Back to Homepage</a>
          <a className="ui-button secondary" href="/store">Browse Store</a>
        </div>
      </div>
    </div>
  );
};

export default CheckoutEmptyPanel;
