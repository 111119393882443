import * as React from 'react';
import classNames from 'classnames';

import { UseFormRegister } from 'react-hook-form';

export type CheckboxProps = {
  label?: string;
  id?: string;
  name: string;
  placeholder?: string;
  disabled?: boolean;
  error?: string;

  // option 1 (uncontrolled): specify a react-hook-form register
  register?: UseFormRegister<any>;

  // option 2 (controlled): specify value and change handler
  checked?: boolean;
  onChange?: (checked: string) => void;
};

const Checkbox = (props: CheckboxProps) => {
  const { label, id, name, checked, placeholder, register, disabled, error } = props;

  const inputId = id || name;

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (props.onChange) props.onChange(event.target.value);
  }

  const input = register
    ? <input type="checkbox" id={inputId} {...register(name)} disabled={disabled} placeholder={placeholder} />
    : <input type="checkbox" id={inputId} name={name} checked={checked == undefined ? false : checked} onChange={onChange} disabled={disabled} placeholder={placeholder} />
  ;

  return (
    <div className={classNames('ui-checkbox', { 'has-error': error })}>
      { input }
      { label ? <label htmlFor={inputId}>{label}</label> : null }
      { error ? <div className="input-error">{ error }</div> : null }
    </div>
  );
}

export default Checkbox;
