import * as React from 'react';
import { useContext, useState } from 'react';
import { padStart } from 'lodash-es';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import { CheckoutContext } from './CheckoutPage';
import { Controller } from 'react-hook-form';
import DatePicker from './DatePicker';
import * as moment from 'moment';

const shipDateMin = moment().add(1, 'days').toDate();

const shipDateMax = moment().add(2, 'months').toDate();

const defaultDate = shipDateMin;

const getDateString = (date: Date | null) => {
  if (date == null) return null;

  const yyyy = date.getFullYear();
  const mm = padStart((date.getMonth() + 1).toString(), 2, '0');
  const dd = padStart(date.getDate().toString(), 2, '0');
  return `${yyyy}-${mm}-${dd}`;
}

const CheckoutShipDatePanel = () => {
  const [{ order }, { setShipDate }] = useContext(CheckoutContext);
  const { handleSubmit, control, watch, setValue } = useForm<{ ship_date: Date | null }>({
    mode: 'all',
    defaultValues: { ship_date: defaultDate },
  });

  const holdOrder = order.ship_on != null;
  const shipDate = watch('ship_date');

  const updateShipDate = (value: Date | null) => {
    setShipDate(getDateString(value));
    setValue('ship_date', value);
  }

  React.useEffect(() => {
    if (holdOrder && shipDate) {
      handleSubmit((data) => {
        const date = data.ship_date;
        setShipDate(getDateString(date));
      })();
    }
  }, [holdOrder, shipDate]);

  return (
    <div className="ui-panel checkout-panel checkout-date">
      <div className="panel-header">
        <h2>When should we deliver your order?</h2>
      </div>
      <div className="panel-body">
        <label className={`ui-selection-panel ${ !holdOrder ? 'selected' : '' }`}>
          <input type="radio" name="checkout-mode" checked={!holdOrder} onChange={() => updateShipDate(null)} className="selection-checkbox" />
          <div className="checkbox unchecked"><FontAwesomeIcon icon={faCircle} /></div>
          <div className="checkbox checked"><FontAwesomeIcon icon={faCheckCircle} /></div>
          <div className="title">Deliver Now</div>
          <div className="content">We’ll ship your order as soon as possible.</div>
        </label>
        <div className="or-divider"></div>
        <label className={`ui-selection-panel ${ holdOrder ? 'selected' : '' }`}>
          <input type="radio" name="checkout-mode" checked={holdOrder} onChange={evt => updateShipDate(defaultDate)} className="selection-checkbox" />
          <div className="checkbox unchecked"><FontAwesomeIcon icon={faCircle} /></div>
          <div className="checkbox checked"><FontAwesomeIcon icon={faCheckCircle} /></div>
          <div className="title">Hold my Order</div>
          <div className="content">
            { holdOrder ? (
              <>
                We’ll ship your order on this date:
                <Controller
                  name="ship_date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      minDate={shipDateMin}
                      maxDate={shipDateMax}
                      name="ship_date"
                      placeholder={getDateString(field.value) || 'Select date'}
                      selected={field.value}
                      onChange={field.onChange}
                      error={field.value ? '' : 'Please specify a date.'}
                    />
                  )}
                />
              </>
            ) : (
              <>We'll ship your order at a later date.</>
            ) }
          </div>
        </label>
      </div>
    </div>
  );
};

export default CheckoutShipDatePanel;
