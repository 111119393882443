import * as React from 'react';
import { useState, useEffect, useCallback, useLayoutEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { Elements, PaymentElement, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';

import { subscribe, unsubscribe, publish } from 'javascripts/utils/events';
import CreditCardPanel from './CreditCardPanel';
import Input from './Input';
import userService, { SetupIntentResponse } from 'javascripts/services/user-service';

const NewCreditCardForm = (props: { onCreateCancelled?: () => void }) => {
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [serverErrors, setServerErrors] = useState<string[]>([]);
  const newCreditCardFormSchema = yup.object().shape({
    card_name: yup.string().required('This field is required.'),
  });
  const { register, handleSubmit, formState: { errors }, reset } = useForm<NewCreditCardData>({
    resolver: yupResolver(newCreditCardFormSchema)
  });

  const onSubmit = async (values: NewCreditCardData) => {
    setLoading(true);
    setServerErrors([]);
    const response = await userService.createCard(values, elements!, true);
    if (response.result === 'error') {
      setServerErrors(response.errors);
    } else {
      setServerErrors([]);
      reset();
      if (props.onCreateCancelled) props.onCreateCancelled();
      publish('CHECKOUT_CREDIT_CARD_SELECTION_REQUESTED', response.card);
      publish('CHECKOUT_UPDATE_USER_DETAILS');
      publish('CLOSE_MODAL', 'cc-management');
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-header sticky">
        <div className="title">
          New Card
        </div>
        <button type="button" className="modal-toggle" data-modal-toggle>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
      <div className="modal-body">
        <div className="ui-form">
          <div className="grid-row">
            <div className="col-12">
              <Input label="Name on Card" name="card_name" register={register} error={errors.card_name?.message} placeholder="Agent Red" />
            </div>
          </div>
          <PaymentElement />
          { serverErrors.length > 0 ?
            <div className="ui-message error">
              <p>Please resolve the following errors:</p>
              <ul>
                { serverErrors.map(error => (
                  <li key={error}>
                    <strong>{error}</strong>
                  </li>
                )) }
              </ul>
            </div>
          : null }
        </div>
      </div>
      <div className="modal-footer sticky">
        <div className="grid-row">
          <div className="col-auto">
            { props.onCreateCancelled ?
              <button type="button" className="ui-button secondary" onClick={props.onCreateCancelled}>Cancel</button>
            :
              <button type="button" className="ui-button secondary" data-modal-toggle>Dismiss</button>
            }
          </div>
          <div className="col"></div>
          <div className="col-auto">
            <button type="submit" className={`ui-button ${ loading ? 'loading' : '' }`}>
              <span className="text h-to-xs">Save and Use Card</span>
              <span className="text h-from-sm">Save</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

const CcManagementModal = (props: { showPreferred?: boolean, hideCheckboxes?: boolean }) => {
  const { showPreferred, hideCheckboxes } = props;
  const [savedCards, setSavedCards] = useState<CreditCardData[]>([]);
  const [selectedCard, setSelectedCard] = useState<CreditCardData | null>(null);
  const [createMode, setCreateMode] = useState(false);
  const [clientSecret, setClientSecret] = useState<string | null>(null);

  const leftColumn = React.useRef<HTMLDivElement>(null);

  const setPaymentMethod = useCallback((card: CreditCardData) => {
    publish('CHECKOUT_CREDIT_CARD_SELECTION_REQUESTED', card);
  }, []);

  const handleOnDelete = useCallback(() => {
    publish('CHECKOUT_UPDATE_USER_DETAILS');
  }, []);

  useEffect(() => {
    axios.get<SetupIntentResponse>('/user/setup_intent').then((res) => {
      setClientSecret(res.data.client_secret);
    })
  }, []);

  // subscribe to events
  useEffect(() => {
    const token1 = subscribe('CHECKOUT_CREDIT_CARDS_UPDATED', (data) => setSavedCards([...data]));
    const token2 = subscribe('CHECKOUT_CREDIT_CARD_SELECTION_UPDATED', (data) => setSelectedCard({...data}));
    return () => {
      unsubscribe(token1);
      unsubscribe(token2);
    }
  }, [setSavedCards, setSelectedCard]);

  // handle automatic modal resizing
  useLayoutEffect(() => {
    if (!leftColumn.current) return;

    const modalRoot = leftColumn.current.closest('.ui-modal-root');
    if (!modalRoot) return;

    modalRoot.classList.remove('modal-xs');
    modalRoot.classList.remove('modal-sm');
    modalRoot.classList.remove('modal-md');
    modalRoot.classList.remove('modal-lg');
    modalRoot.classList.remove('modal-xl');

    if (createMode) {
      modalRoot.classList.add('modal-lg');
      modalRoot.classList.add('edit-form-active');
    } else {
      if (savedCards.length === 0) modalRoot.classList.add('modal-md');
      else modalRoot.classList.add('modal-xs');
      modalRoot.classList.remove('edit-form-active');
    }
  }, [createMode, leftColumn.current, savedCards]);

  // build list of card panels
  var paymentCards = [];
  for (const card of savedCards) {
    const selected = selectedCard?.id === card.id;
    paymentCards.push(
      <CreditCardPanel key={card.id} card={card} selected={selected} editable showPreferred={showPreferred} hideCheckbox={hideCheckboxes} setPaymentMethod={setPaymentMethod} onDelete={handleOnDelete} />
    );
  }

  if (!clientSecret) return null;

  const ElementsProvider = ({ children }: { children: React.ReactNode}) => {
    const options: StripeElementsOptions = {
      clientSecret,
      appearance: {
        variables: {
          colorText: 'black',
          colorTextPlaceholder: '#8F8F8F',
          fontFamily: 'ws_inter, sans-serif',
          colorBackground: '#F7F7F7'
        },
        rules: {
          '.Label': {
            color: '#8F8F8F',
            fontSize: '.8rem',
            fontWeight: '700',
            lineHeight: '1rem',
            margin: '0 0 0.25rem',
            textTransform: 'uppercase'
          },
          '.Input': {
            border: '1px solid rgba(0,0,0,.1)',
            borderRadius: '3rem',
            fontSize: '18px',
            lineHeight: '1.5rem',
            outline: 'none',
            padding: '0.75rem 1rem',
            transition: 'all .15s ease'
          },
          '.Input::placeholder': {
            fontStyle: 'italic',
          },
          '.Input:hover': {
            borderColor: '#8F8F8F'
          },
          '.Input:focus': {
            borderColor: '#000',
            boxShadow: '0 0 0 3px hsla(0,0%,56%,.25)'
          },
          '.Input--invalid': {
            borderColor: '#C20000',
            color: '#C20000',
          },
          '.Input--invalid:hover': {
            borderColor: '#FF2A2A',
          },
          '.Input--invalid:focus': {
            borderColor: '#FF2A2A',
            boxShadow: '0 0 0 3px rgba(194,0,0,.25)'
          },
          '.Error': {
            color: '#C20000',
            fontSize: '.8rem',
            fontWeight: '700',
            lineHeight: '1rem',
            margin: '0.5rem 1rem 0'
          }
        }
      }
    };

    // @ts-ignore
    return <Elements stripe={window.STRIPE} options={options}>{children}</Elements>;
  };

  if (paymentCards.length === 0) {
    return (
      <div className="left-column" ref={leftColumn}>
        <ElementsProvider>
          <NewCreditCardForm />
        </ElementsProvider>
      </div>
    );
  } else {
    return (
      <>
        <div className="left-column" ref={leftColumn}>
          <div className="modal-header sticky">
            <div className="title">
              Manage Credit Cards
            </div>
            <button className="h-from-md modal-toggle" data-modal-toggle>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className="modal-body">
            <div className="ui-selection-list">
              { paymentCards }
            </div>
          </div>
          <div className="modal-footer sticky">
            <div className="grid-row">
              <div className="col-auto">
                <button type="button" className="ui-button secondary" data-modal-toggle>
                  <span className="text">Dismiss</span>
                </button>
              </div>
              <div className="col"></div>
              <div className="col-auto">
                { !createMode ?
                  <button type="submit" className="ui-button" onClick={() => setCreateMode(!createMode)}>
                    <span className="text h-to-xs">New Card</span>
                    <span className="text h-from-sm">New</span>
                  </button>
                : null }
              </div>
            </div>
          </div>
        </div>
        <div className="right-column">
          <ElementsProvider>
            <NewCreditCardForm onCreateCancelled={() => setCreateMode(false)} />
          </ElementsProvider>
        </div>
      </>
    );
  }
};

export default CcManagementModal;