export type CostType =
    'subtotal'
  | 'tax'
  | 'shipping'
  | 'icepacks'
  | 'credits'
  | 'coupon'
  | 'total'
  | 'savings'
;

export interface SavingsSource {
  bottle: Money;
  coupon: Money;
  credits: Money;
  shipping: Money;
  total: Money;
}

export interface SavingsBreakdown {
  name: string;
  value: Money;
}

export interface Savings {
  total: Money;
  breakdown: SavingsBreakdown[];
}

export interface CostSummaryRow {
  name: string;
  value: Money;
  type: CostType;
  caSalesTax?: boolean;
  withLockerShipping?: boolean;
}

export interface CostSummarySource {
  // generic fields
  subtotal?: Money;
  ice_packs?: Money;
  credit?: Money;

  // cart fields
  estimated_shipping?: Money;
  estimated_total?: Money;

  // checkout
  shipping?: Money;
  with_locker_shipping?: boolean;
  total?: Money;
  savings?: SavingsSource;
  tax?: Money;
  ca_sales_tax?: boolean;
  coupon_discount?: Money;
  at_cost_discount?: Money;
}

const getCostRow = (value: Money, name: string, type: CostType, caSalesTax?: boolean, withLockerShipping?: boolean) => {
  return { name, value, type, caSalesTax, withLockerShipping };
}

export const generateCostSummary = <T extends CostSummarySource>(source: T) => {
  const costs: CostSummaryRow[] = [];
  const savings: Savings = {
    total: source.savings?.total || {
      str: '0.00',
      num: 0,
      cents: 0,
    },
    breakdown: []
  }

  if (source.subtotal) costs.push(getCostRow(source.subtotal, 'Subtotal', 'subtotal'));

  if (source.coupon_discount) costs.push(getCostRow(source.coupon_discount, 'Coupon Discount', 'coupon'));
  if (source.at_cost_discount) costs.push(getCostRow(source.at_cost_discount, 'At Cost Discount', 'coupon'));
  if (source.credit) costs.push(getCostRow(source.credit, 'Credits', 'credits'));

  if (source.estimated_shipping) costs.push(getCostRow(source.estimated_shipping, 'Estimated Shipping', 'shipping'));
  if (source.shipping) costs.push(getCostRow(source.shipping, 'Shipping', 'shipping', false, source.with_locker_shipping));
  if (source.ice_packs) costs.push(getCostRow(source.ice_packs, 'Ice Packs', 'icepacks'));

  if (source.tax && !source.ca_sales_tax) costs.push(getCostRow(source.tax, 'Tax', 'tax'));

  if (source.tax && source.ca_sales_tax) costs.push(getCostRow(source.tax, 'CA Sales Tax', 'tax', source.ca_sales_tax));

  if (source.estimated_total) costs.push(getCostRow(source.estimated_total, 'Current Total', 'total'));
  if (source.total) costs.push(getCostRow(source.total, 'Grand Total', 'total'));

  if (source.savings) {
    if (source.savings.bottle.num !== 0) savings.breakdown.push({ name: 'Sale Discount: ', value: source.savings.bottle });
    if (source.savings.coupon.num !== 0) savings.breakdown.push({ name: 'Coupon: ', value: source.savings.coupon });
    if (source.savings.credits.num !== 0) savings.breakdown.push({ name: 'Credits: ', value: source.savings.credits });
    if (source.savings.shipping.num !== 0) savings.breakdown.push({ name: 'Shipping: ', value: source.savings.shipping });
  }

  return { costs, savings };
}
