import * as React from 'react';
import { useState, useEffect, useCallback, useLayoutEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { subscribe, unsubscribe, publish } from 'javascripts/utils/events';
import AddressPanel from './AddressPanel';
import AddressForm from './AddressForm';

const AddressManagementModal = (props: { showPreferred?: boolean, hideCheckboxes?: boolean }) => {
  const { showPreferred, hideCheckboxes } = props;
  const [savedAddresses, setSavedAddresses] = useState<AddressData[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<AddressData | null>(null);
  const [editAddress, setEditAddress] = useState<AddressData | null>(null);
  const [createMode, setCreateMode] = useState(false);

  const leftColumn = React.useRef<HTMLDivElement>(null);

  const setShippingAddress = useCallback((address: AddressData) => {
    publish('CHECKOUT_ADDRESS_SELECTION_REQUESTED', address);
  }, []);

  const handleOnDelete = useCallback(() => {
    publish('CHECKOUT_UPDATE_USER_DETAILS');
  }, []);

  const handleOnEdit = useCallback((address: AddressData) => {
    setCreateMode(false);
    setEditAddress(address);
  }, []);

  const cancelEdit = useCallback(() => {
    setEditAddress(null);
  }, []);

  const onFindPickupAddressClick = useCallback(() => {
    publish('OPEN_MODAL', 'carrier-hold-locations');
    publish('CLOSE_MODAL', 'address-management');
  }, []);

  // subscribe to events
  useEffect(() => {
    const token1 = subscribe('CHECKOUT_ADDRESSES_UPDATED', (data) => {
      setSavedAddresses([...data]);
      setCreateMode(false);
      cancelEdit();
    });
    const token2 = subscribe('CHECKOUT_ADDRESS_SELECTION_UPDATED', (data) => setSelectedAddress({...data}));
    return () => {
      unsubscribe(token1);
      unsubscribe(token2);
    }
  }, [setSavedAddresses, setSelectedAddress]);

  // handle automatic modal resizing
  useLayoutEffect(() => {
    if (!leftColumn.current) return;

    const modalRoot = leftColumn.current.closest('.ui-modal-root');
    if (!modalRoot) return;

    modalRoot.classList.remove('modal-xs');
    modalRoot.classList.remove('modal-sm');
    modalRoot.classList.remove('modal-md');
    modalRoot.classList.remove('modal-lg');
    modalRoot.classList.remove('modal-xl');

    if (editAddress || createMode) {
      modalRoot.classList.add('modal-xl');
      modalRoot.classList.add('edit-form-active');
    } else {
      if (savedAddresses.length === 0) modalRoot.classList.add('modal-md');
      else modalRoot.classList.add('modal-sm');
      modalRoot.classList.remove('edit-form-active');
    }
  }, [editAddress, createMode, leftColumn.current, savedAddresses]);

  // build list of address panels
  var addresses = [];
  for (const address of savedAddresses) {
    const selected = selectedAddress?.id === address.id;
    addresses.push(
      <AddressPanel key={address.id} address={address} selected={selected} editable showPreferred={showPreferred} hideCheckbox={hideCheckboxes} setShippingAddress={setShippingAddress} onEdit={() => handleOnEdit(address)} onDelete={handleOnDelete} />
    );
  }

  if (addresses.length === 0) {
    return (
      <div className="left-column" ref={leftColumn}>
        <AddressForm />
      </div>
    );
  } else {
    return (
      <>
        <div className="left-column" ref={leftColumn}>
          <div className="modal-header sticky">
            <div className="title">
              Manage Addresses
            </div>
            <button className="modal-toggle" data-modal-toggle>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className="modal-body">
            <div className="ui-selection-list">
              { addresses }
            </div>
          </div>
          <div className="modal-footer sticky">
            <div className="grid-row">
              <div className="col-auto">
                <button type="button" className="ui-button small secondary" data-modal-toggle>
                  <span className="text">Dismiss</span>
                </button>
              </div>
              <div className="col"></div>
              <div className="col-auto">
                { !createMode ?
                  <>
                    <button type="button" className="ui-button small" style={{marginRight: '1rem'}} disabled={editAddress != null} onClick={onFindPickupAddressClick}>
                      <span className="text h-to-xs">Find Pickup Address</span>
                      <span className="text h-from-sm">Pickup</span>
                    </button>
                    <button type="button" className="ui-button small" disabled={editAddress != null} onClick={() => setCreateMode(!createMode)}>
                      <span className="text h-to-xs">New Address</span>
                      <span className="text h-from-sm">New</span>
                    </button>
                  </>
                : null }
              </div>
            </div>
          </div>
        </div>
        <div className="right-column">
          { editAddress == null ?
            <div key="new"><AddressForm onCreateCancelled={() => setCreateMode(false)} /></div>
          :
            <div key={editAddress.id}><AddressForm address={editAddress} onEditCancelled={cancelEdit} /></div>
          }
        </div>
      </>
    );
  }
};

export default AddressManagementModal;