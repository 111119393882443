import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';

// handle loading errors
document.addEventListener('error', e => {
  const target = e.target;
  if (!(target instanceof HTMLImageElement)) return;

  const validClasses = ['lazyload', 'lazyloading', 'lazyloaded'];
  let valid = false;
  for (const c of validClasses) {
    if (target.classList.contains(c)) valid = true;
  }
  if (!valid) return;

  target.classList.add('lazyload-error');
  const errorSrc = target.getAttribute('data-error-src');
  target.srcset = '';
  target.src = errorSrc || '/loading-error.svg';
}, true);
