import gsap from 'gsap';
import { subscribe, publish } from 'javascripts/utils/events';

import cartService from '../services/cart-service';

const PROPS_CLOSED = {
  opacity: 0,
  x: '100%'
};

const PROPS_OPEN = {
  opacity: 1,
  x: 0
};

const toggleHandler = (evt: Event) => {
  toggleSlideoutCart();
  evt.preventDefault();
  return false;
};

const resetToggles = () => {
  const toggles = document.querySelectorAll('.slideout-cart-toggle');

  // reset click handlers for all menu toggles
  toggles.forEach(toggle => {
    toggle.removeEventListener('click', toggleHandler);
    toggle.addEventListener('click', toggleHandler);
  });
}

let open = false;
const toggleSlideoutCart = () => {
  const root = document.getElementById('slideout-cart');
  const overlay = document.querySelector('.slideout-cart-overlay');
  resetToggles();

  open = !open;
  if (open) {
    // opening animation sequence
    gsap.set(root, { ...PROPS_OPEN, display: 'flex' });
    gsap.from(root, {
      ...PROPS_CLOSED,
      duration: 0.5,
      ease: 'expo.out',
      clearProps: 'right'
    });

    // open menu overlay
    gsap.set(overlay, { display: 'block', opacity: 0 });
    gsap.to(overlay, {
      opacity: 1,
      duration: 0.5,
      ease: 'expo.out'
    });
  } else {
    // closing animation sequence
    gsap.to(root, {
      ...PROPS_CLOSED,
      duration: 0.5,
      ease: 'expo.inOut',
      clearProps: 'right',
      onComplete: () => {
        gsap.set(root, { display: 'none' });
      }
    });

    // close menu overlay
    gsap.to(overlay, {
      opacity: 0,
      duration: 0.5,
      ease: 'expo.inOut',
      onComplete: () => {
        gsap.set(overlay, { display: 'none' });
      }
    });
  }
};

const handleQueryStringCartItems = (urlParams: URLSearchParams) => {
  const rawItems: { key: string, value: string}[] = [];
  urlParams.forEach((value, key) => {
    if (key.startsWith('cart_items[]')) {
      rawItems.push({ key, value });
    }
  });

  urlParams.forEach((value, key) => {
    if (key.startsWith('cart_items[]')) {
      urlParams.delete(key);
    }
  });

  const parsedItems = rawItems.map(item => {
    // @ts-ignore
    const [, key] = item.key.match(/\[\]\[(.*?)\]/);
    return { key, value: item.value };
  });

  const cartItems: {sale_id: number, qty?: number}[] = [];
  parsedItems.forEach((item) => {
    const {key, value} = item;
    if (key === 'sale_id') {
      cartItems.push({ sale_id: parseInt(value) });
    } else if (key === 'qty') {
      const qty = {qty: parseInt(value)}
      const lastItem = cartItems[cartItems.length - 1]
      Object.assign(lastItem, qty)
    }
  });

  if (cartItems.length === 0) return

  cartItems.forEach((item) => {
    publish('CART_UPDATE_REQUESTED', {
      saleId: parseInt(item.sale_id.toString()),
      quantity: item.qty,
      shouldNotDebounce: true,
    });
  })
  publish('CART_OPEN_OVERLAY')

  const newUrlParams = urlParams.size > 0 ? `?${urlParams.toString()}` : ''
  const newUrl = `${window.location.pathname}${newUrlParams}`
  window.history.replaceState({}, document.title, newUrl)
}

const handleQueryStringCoupon = (urlParams: URLSearchParams) => {
  const coupon = urlParams.get('coupon')

  if (!coupon) return

  window.localStorage.setItem('QUERY_STRING_COUPON', coupon);
}

const handleUrlParams = () => {
  const urlParams = new URLSearchParams(window.location.search);

  handleQueryStringCartItems(urlParams)
  handleQueryStringCoupon(urlParams)
}

const onLoad = async () => {
  await cartService.initialize();
  publish('CART_REFRESH');

  resetToggles();

  // subscribe to PubSub cart open events
  subscribe('CART_OPEN_OVERLAY', () => {
    if (!open) toggleSlideoutCart();
  });

  handleUrlParams();
}

if (document.readyState === 'complete') {
  onLoad();
} else {
  document.onreadystatechange = () => {
    if (document.readyState === 'complete') onLoad();
  };
}
