import { useCallback, useState } from 'react';

import checkoutService from '../services/checkout-service';

/**
 * Checkout hook that allows read-write access to checkout options fetched from /checkout/user_details.
 */
const useCheckoutPreview = () => {
  const [preview, setPreview] = useState<Loadable<CheckoutPreview>>({ loading: true });

  // helper methods
  const updatePreview = useCallback(async (state: CheckoutState) => {
    if (state.order.complete) return;
    setPreview((oldState) => ({ ...oldState, _key: 0, loading: true }));
    const newPreview = await checkoutService.previewCheckout(state);
    setPreview({ ...newPreview, loading: false });
  }, []);

  // hook outputs
  const state: Loadable<CheckoutPreview> = preview;
  const actions = { updatePreview };
  return [state, actions] as [state: Loadable<CheckoutPreview>, actions: typeof actions];
}

export default useCheckoutPreview;
