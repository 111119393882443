import { useCallback, useEffect, useState } from 'react';

import { publish, subscribe, unsubscribe } from './events';

/**
 * Cart hook that allows read-write access to active cart data.
 */
const useCart = () => {
  const [cartData, setCartData] = useState<Loadable<CartData>>({ loading: true });

  // event subscriptions
  useEffect(() => {
    const token1 = subscribe('CART_UPDATE_REQUESTED', (item) => {
      // update the quantity of a specific item early even while the
      // actual update is still deferred (because of debounce)
      const cartItem = cartData.items?.find(i => i.sale.id === item.saleId);
      if (cartItem && item.quantity != undefined) {
        cartItem.qty = item.quantity;
        setCartData({ ...cartData, loading: false });
      }
    });
    const token2 = subscribe('CART_UPDATING', () => {
      setCartData({ ...cartData, loading: true });
    });
    const token3 = subscribe('CART_UPDATED', (data) => {
      setCartData({ ...data, loading: false });
    });

    // dispose subscriptions when the component rerenders
    return () => {
      unsubscribe(token1);
      unsubscribe(token2);
      unsubscribe(token3);
    }
  }, [cartData, setCartData]);

  // helper methods
  const updateCart = useCallback(() => {
    publish('CART_REFRESH');
  }, []);

  // hook outputs
  const state: Loadable<CartData> = cartData;
  const actions = { updateCart };
  return [state, actions] as [state: Loadable<CartData>, actions: typeof actions];
}

export default useCart;
