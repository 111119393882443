import * as React from 'react';
import { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck } from '@fortawesome/free-solid-svg-icons';

import { CheckoutContext } from './CheckoutPage';

const CheckoutLockerOnlyPanel = () => {
  const [state, { setAtCostShipping }] = useContext(CheckoutContext);
  const { user, order } = state;

  const atCost = user.at_cost || false;
  const atCostShipping = order.at_cost_shipping || false;

  if (!atCost || !order.has_shipping) return null;

  return (
    <div className="checkout-panel checkout-locker-only">
      <div className="ui-switch">
        <input type="checkbox" id="at-cost-shipping" name="at-cost-shipping" checked={atCostShipping} onChange={evt => setAtCostShipping(evt.target.checked)} />
        <label htmlFor="at-cost-shipping">
          At-Cost: Pay to Ship Order <FontAwesomeIcon icon={faTruck} />
          <div className="switch-knob"></div>
        </label>
      </div>
    </div>
  );
};

export default CheckoutLockerOnlyPanel;
