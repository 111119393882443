import * as React from 'react';

import CheckoutPanels from './CheckoutPanels';
import CheckoutSummary from './CheckoutSummary';
import CheckoutThanksPanel from './CheckoutThanksPanel';

import useCheckout from 'javascripts/utils/useCheckout';

// ---- DATA CONTEXT -------------------------------------------------------- //

// fetch URL query params to check if we should allow locker-only checkout
const urlSearchParams = new URLSearchParams(window.location.search);
// @ts-ignore
const params = Object.fromEntries(urlSearchParams.entries());

const lockers = (params.lockers as string || '').split(',').filter(i => i.trim().length > 0).map(i => parseInt(i.trim()));
const locker_only = lockers.length > 0;

/**
 * Setup global context provider for downstream components.
 */
const DEFAULT_STATE: CheckoutState = {
  cart: { loading: true },
  user: { loading: true },
  order: { order_type: 'single_order', locker_only },
  preview: { loading: true }
};

if (lockers.length > 0) {
  DEFAULT_STATE.order.lockers_for_shipping_index = lockers;
  DEFAULT_STATE.order.locker_selection_dirty = true;
}

// Ignore the type signature error from this default context.
// @ts-ignore
const DEFAULT_CONTEXT: ReturnType<typeof useCheckout> = [DEFAULT_STATE, {}];

export const CheckoutContext = React.createContext<ReturnType<typeof useCheckout>>(DEFAULT_CONTEXT);

export function getBrowserVisibilityProp() {
  if (typeof document.hidden !== "undefined") {
    // Opera 12.10 and Firefox 18 and later support
    return "visibilitychange"
  } else if (typeof (document as any).msHidden !== "undefined") {
    return "msvisibilitychange"
  } else if (typeof (document as any).webkitHidden !== "undefined") {
    return "webkitvisibilitychange"
  } else {
    return null
  }
}

export function getBrowserDocumentHiddenProp() {
  if (typeof document.hidden !== "undefined") {
    return "hidden"
  } else if (typeof (document as any).msHidden !== "undefined") {
    return "msHidden"
  } else if (typeof (document as any).webkitHidden !== "undefined") {
    return "webkitHidden"
  }
  return null
}

export function getIsDocumentHidden() {
  if(getBrowserDocumentHiddenProp()){
    // @ts-ignore
    return !document[getBrowserDocumentHiddenProp()]
  } else {
    return false
  }
}

// ---- CHECKOUT PAGE ------------------------------------------------------- //
/**
 * Root component for the checkout page.
 * Responsible for rendering everything and consolidating all data during checkout.
 */
const CheckoutPage = () => {
  const [state, actions] = useCheckout(DEFAULT_STATE);
  const [isVisible, setIsVisible] = React.useState(getIsDocumentHidden())

  const onVisibilityChange = () => {
    setIsVisible(getIsDocumentHidden());
    if(!isVisible) {
      location.reload();
    }
  }

  React.useEffect(() => {
    const visibilityChange = getBrowserVisibilityProp()

    if(visibilityChange) document.addEventListener(visibilityChange, onVisibilityChange, false)
  })

  // display global loading state if render-critical info haven't loaded yet
  if (!state.user.initialized) return (
    <div className="container">
      <div className="grid-row">
        <div className="col-12 col-lg-8">
          <div className="ui-panel checkout-panel placeholder loading"></div>
          <div className="ui-panel checkout-panel placeholder loading"></div>
          <div className="ui-panel checkout-panel placeholder loading"></div>
        </div>

        <div className="col-12 col-lg">
          <div className="ui-panel checkout-panel placeholder loading"></div>
        </div>
      </div>
    </div>
  );

  // display thanks page
  if (state.order.complete) return (
    <CheckoutContext.Provider value={[state, actions]}>
      <div className="container">
        <div className="grid-row">
          <div className="col-12 col-lg-8">
            <CheckoutThanksPanel />
          </div>
          <div className="col-12 col-lg">
            <CheckoutSummary readonly />
          </div>
        </div>
      </div>
    </CheckoutContext.Provider>
  );

  return (
    <CheckoutContext.Provider value={[state, actions]}>
      <div className="container">
        <div className="grid-row">
          <div className="col-12 col-lg-8">
            <CheckoutPanels />
          </div>
          <div className="col-12 col-lg">
            <CheckoutSummary />
          </div>
        </div>

        <div className="grid-row">
          <div className="col-12 col-lg-8">
            <div className="disclaimer styled-content">
              <p><strong>ALL ALCOHOLIC BEVERAGES PURCHASED FROM WINE SPIES ARE SOLD IN THE STATE OF CALIFORNIA AND TITLE PASSES TO THE BUYER IN CALIFORNIA.</strong></p>

              <p>As your agent, we can assist in selecting a common carrier for the shipment of wine that you have purchased and own. If you would like to make your own shipping arrangements, please reply to your order receipt email and let us know how you would like to proceed.</p>

              <p>The majority of states maintain laws and regulations that control or restrict the importation of alcohol. In all cases, the purchaser is responsible for complying with the laws and regulations, including in particular those relating to the import of alcohol, in effect in the state to which the purchaser is shipping alcohol.</p>
            </div>
          </div>
        </div>
      </div>
    </CheckoutContext.Provider>
  );
};

export default CheckoutPage;