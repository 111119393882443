import * as React from 'react';
import { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faArrowRight, faCheckCircle, faQuestionCircle, faTruck, faWarehouse } from '@fortawesome/free-solid-svg-icons';

import { CheckoutContext } from './CheckoutPage';
import { publish } from 'javascripts/utils/events';

const CheckoutModePanel = (props: { readonly?: boolean }) => {
  const [state, { setOrderType, setAtCostShipping }] = useContext(CheckoutContext);
  const { user, order, preview } = state;
  const { readonly } = props;

  const atCost = user.at_cost || false;
  const atCostShipping = order.at_cost_shipping || false;
  const locker = order.order_type === 'locker_order';
  const single = order.order_type === 'single_order';

  let singleOrderMessage = (
    <>We’ll ship your orders immediately, or on a specific date when you hold your order.</>
  );
  if (atCost) singleOrderMessage = (
    <>
      { atCostShipping ? (
        <>We’ll place your orders at reduced prices, but you’ll have to pay for shipping.</>
      ) : (
        <>Your order will be placed with reduced (at-cost) prices, and you’ll need to pick it up from our warehouse.</>
      )}
    </>
  );

  let addToLockerMessage = (
    <>We’ll keep your orders safe in our warehouse, and you can ship them whenever you want.</>
  );
  if (atCost) addToLockerMessage = (
    <>
      { atCostShipping ? (
        <>We’ll place your orders at reduced prices, and you’ll pay for shipping to get your order delivered to you.</>
      ) : (
        <>Your order will be placed with reduced (at-cost) prices, and we’ll keep them in our warehouse until you ship them.</>
      )}
    </>
  );

  let readonlyAtCostShippingStatus = <></>;
  if (readonly && atCost && order.has_shipping) readonlyAtCostShippingStatus = (
    <>
      { atCostShipping ?
        <div className="at-cost-shipping-status ship">
          At-Cost: Pay to Ship Order
          <FontAwesomeIcon icon={faTruck} />
        </div>
      :
        <div className="at-cost-shipping-status pickup">
          At-Cost: Warehouse Pickup
          <FontAwesomeIcon icon={faWarehouse} />
        </div>
      }
    </>
  );

  let atCostBadge = <></>;
  if (atCost) atCostBadge = <span className="badge">At Cost</span>;

  const atCostPayToShipCheckbox = (
    <>
      <div className="ui-checkbox">
        <input type="checkbox" id="at-cost-shipping" name="at-cost-shipping" checked={atCostShipping} onChange={evt => setAtCostShipping(evt.target.checked)} />
        <label htmlFor="at-cost-shipping">At-Cost: Pay to Ship Order</label>
      </div>
    </>
  );

  if (readonly) return (
    <>
      <div className="section-label">Order Type</div>

      <div className="checkout-panel checkout-mode">
        <div className="grid-row">
          <div className="col-12 col-lg-6">
            { locker ?
              <div className="selection-panel-wrapper">
                <label className="ui-selection-panel selected">
                  <input type="radio" name="checkout-mode" checked={locker} onChange={() => { /* do nothing */ }} className="selection-checkbox" />
                  <div className="checkbox unchecked"><FontAwesomeIcon icon={faCircle} /></div>
                  <div className="checkbox checked"><FontAwesomeIcon icon={faCheckCircle} /></div>
                  <div className="title">Add to Locker { atCostBadge }</div>
                  <div className="content">{ addToLockerMessage }</div>
                </label>
                { readonlyAtCostShippingStatus }
                <a className="locker-info-toggle no-left-margin" onClick={() => publish('OPEN_MODAL', 'what-are-lockers')}>
                What are Lockers?
                <FontAwesomeIcon icon={faQuestionCircle} />
                </a>
              </div>
            :
              <div className="selection-panel-wrapper">
                <label className={`ui-selection-panel ${ single ? 'selected' : '' }`}>
                  <input type="radio" name="checkout-mode" checked={single} onChange={() => { /* do nothing */ }} className="selection-checkbox" />
                  <div className="checkbox unchecked"><FontAwesomeIcon icon={faCircle} /></div>
                  <div className="checkbox checked"><FontAwesomeIcon icon={faCheckCircle} /></div>
                  <div className="title">Single Order { atCostBadge }</div>
                  <div className="content">{ singleOrderMessage }</div>
                </label>
                { readonlyAtCostShippingStatus }
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div className="ui-panel checkout-panel checkout-mode">
      <div className="panel-header">
        <h2>How would you like to check out?</h2>
      </div>
      <div className="panel-body">
        <div className="selection-panel-wrapper">
          { preview.has_magnum_products ? (
            <>
              <label className="ui-selection-panel disabled">
                <div className="checkbox unchecked"><FontAwesomeIcon icon={faCircle} /></div>
                <div className="title">Add to Locker (Unavailable)</div>
                <div className="content">{ addToLockerMessage }</div>
              </label>
              <div className="ui-message magnum error">
                <p>Your order cannot be added to a Locker because you have Magnum bottles in your cart.</p>
                <button className="ui-button small" data-modal-toggle="magnum-lockers">
                  <span className="text">More Info</span>
                  <span className="icon">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </span>
                </button>
              </div>
            </>
          ) : (
            <>
              <label className={`ui-selection-panel ${ locker ? 'selected' : '' }`}>
                <input type="radio" name="checkout-mode" checked={locker} onChange={() => setOrderType('locker_order')} className="selection-checkbox" />
                <div className="checkbox unchecked"><FontAwesomeIcon icon={faCircle} /></div>
                <div className="checkbox checked"><FontAwesomeIcon icon={faCheckCircle} /></div>
                <div className="title">Add to Locker { atCostBadge }</div>
                <div className="content">{ addToLockerMessage }</div>
              </label>
              { atCost && locker ? atCostPayToShipCheckbox : null }
              <a className="locker-info-toggle" onClick={() => publish('OPEN_MODAL', 'what-are-lockers')}>
                What are Lockers?
                <FontAwesomeIcon icon={faQuestionCircle} />
              </a>
            </>
          ) }
        </div>
        <div className="or-divider"></div>
        <div className="selection-panel-wrapper">
          { preview.has_vault_products ? (
            <>
              <label className="ui-selection-panel disabled">
                <div className="checkbox unchecked"><FontAwesomeIcon icon={faCircle} /></div>
                <div className="title">Single Order (Unavailable)</div>
                <div className="content">{ singleOrderMessage }</div>
              </label>
              <div className="ui-message vault error">
                <p>You cannot place a Single Order because you have Vault bottles in your cart.</p>
                <button className="ui-button small" data-modal-toggle="vault-info">
                  <span className="text">More Info</span>
                  <span className="icon">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </span>
                </button>
              </div>
            </>
          ) : (
            <>
              <label className={`ui-selection-panel ${ single ? 'selected' : '' }`}>
                <input type="radio" name="checkout-mode" checked={single} onChange={() => setOrderType('single_order')} className="selection-checkbox" />
                <div className="checkbox unchecked"><FontAwesomeIcon icon={faCircle} /></div>
                <div className="checkbox checked"><FontAwesomeIcon icon={faCheckCircle} /></div>
                <div className="title">Single Order { atCostBadge }</div>
                <div className="content">{ singleOrderMessage }</div>
              </label>
              { atCost && single ? atCostPayToShipCheckbox : null }
            </>
          ) }
        </div>
      </div>
    </div>
  );
};

export default CheckoutModePanel;
