import * as React from 'react';

import CartItem from './CartItem';

const SlideoutCartItems = ({ items }: { items: CartItemData[] }) => {
  if (items.length > 0) {
    const cartItems = items.map((item, i) => (
      <CartItem key={i} item={item} editable />
    ));

    return <>{cartItems}</>;
  } else {
    return (
      <div className="cart-empty">You have no items in your cart.</div>
    );
  }
};

export default SlideoutCartItems;
