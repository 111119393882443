import gsap from 'gsap';
import { subscribe } from 'javascripts/utils/events';
import tippy from 'tippy.js';

// ---- mega menu toggle behavior ------------------------------------------- //
const root = document.getElementById('header-nav');
const menu = root?.querySelector('.nav-mega-menu');
const overlay = root?.querySelector('.nav-mega-menu-overlay');
const toggles = root?.querySelectorAll('.mega-menu-toggle');

const PROPS_CLOSED = {
  opacity: 0,
  height: '8rem',
  paddingBottom: '0'
};

const PROPS_OPEN = {
  opacity: 1,
  height: 'auto',
  paddingBottom: '2rem'
};

let open = false;
const toggleMegaMenu = () => {
  if (!root || !menu || !overlay) return;

  open = !open;
  if (open) {
    // opening animation sequence
    root.classList.add('inverted');
    gsap.set(menu, { ...PROPS_OPEN, display: 'block' });
    gsap.from(menu, {
      ...PROPS_CLOSED,
      duration: 0.5,
      ease: 'power2.out',
      clearProps: 'height, paddingBottom'
    });

    // open menu overlay
    gsap.set(overlay, { display: 'block', opacity: 0 });
    gsap.to(overlay, {
      opacity: 1,
      duration: 0.5,
      ease: 'power2.out'
    });
  } else {
    // closing animation sequence
    root.classList.remove('inverted');
    gsap.to(menu, {
      ...PROPS_CLOSED,
      duration: 0.5,
      ease: 'power2.inOut',
      clearProps: 'height, paddingBottom',
      onComplete: () => {
        gsap.set(menu, { display: 'none' });
      }
    });

    // close menu overlay
    gsap.to(overlay, {
      opacity: 0,
      duration: 0.5,
      ease: 'power2.inOut',
      onComplete: () => {
        gsap.set(overlay, { display: 'none' });
      }
    });
  }
};

// add click handlers for all menu toggles
toggles?.forEach(toggle => {
  toggle.addEventListener('click', (evt) => {
    toggleMegaMenu();
    evt.preventDefault();
    return false;
  });
});

// ---- handle cart updates ------------------------------------------------- //
subscribe('CART_UPDATED', (data) => {
  const empty = !data.total_qty;
  // find and update on-page cart counters
  document.querySelectorAll('[data-cart-qty]').forEach(el => {
    const scope = el.getAttribute('data-cart-qty');
    let cart = document.querySelector('.cart');

    if (empty) {
      el.classList.add('empty');
      el.innerHTML = scope === 'mega-menu' ? 'Empty' : '0';

      // Show Mobile Cart Icon
      if (scope == 'header') { cart?.classList.add('hidden') }
    } else {
      el.classList.remove('empty');
      el.innerHTML = data.total_qty.toString();

      // Show Mobile Cart Icon
      if (scope == 'header') { cart?.classList.remove('hidden') }
    }
  });
});

// ---- add user popup behavior --------------------------------------------- //

const template = document.getElementById('user-popup-template');
if (template) tippy('.user-popup-toggle', {
  content: template.innerHTML,
  allowHTML: true,
  theme: 'light',
  interactive: true,
  appendTo: 'parent'
});
