import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSnowflake, faTruck, faWarehouse } from '@fortawesome/free-solid-svg-icons';

export type TempControlOptionalProps = {
  allow_ice_packs?: boolean;
  ice_packs: boolean;
  zone_skipped: boolean;
  onChangeIcePacks: (ice_packs: boolean) => void;
  onChangeZoneSkipped: (zone_skipped: boolean) => void;
  show_label?: boolean;
  label_string?: string;
};

const TempControlOptional = (props: TempControlOptionalProps) => {
  const { allow_ice_packs, ice_packs, zone_skipped, onChangeIcePacks, onChangeZoneSkipped, show_label, label_string } = props;

  return (
    <>
      <div className="temp-control-viz">
        <div className="terminal active">
          <FontAwesomeIcon icon={faWarehouse} />
        </div>
        <div className="node active"></div>

        <div className={`path large ${ zone_skipped ? 'active' : '' }`}>
          <div className="path-node">
            <FontAwesomeIcon icon={faTruck} />
          </div>
        </div>
        <div className={`node ${ zone_skipped ? 'active' : '' }`}></div>

        { allow_ice_packs ?
        <>
          <div className={`path ${ ice_packs ? 'active' : '' }`}>
            <div className="path-node">
              <FontAwesomeIcon icon={faSnowflake} />
            </div>
          </div>
          <div className={`node ${ ice_packs ? 'active' : '' }`}></div>
        </>
        :
        <>
          <div className={`path ${ zone_skipped ? 'active' : '' }`}/>
          <div className={`node ${ zone_skipped ? 'active' : '' }`}/>
        </>
        }

        <div className={`terminal ${ (allow_ice_packs ? ice_packs : zone_skipped) ? 'active' : '' }`}>
          <FontAwesomeIcon icon={faHome} />
        </div>
      </div>

      <div className="message">
        <p><strong>Protect your wine from extreme temperatures — for free.</strong></p>
        <p>If you're concerned about the weather's effect on your wine, we'll pamper it for you in our fleet of insulated trucks, all the way from our California HQ to a secret regional spy hub near you. For the final leg of its journey, your order will be handed off to the parcel carrier - who will then deliver it to you.</p>
      </div>

      <div className="ui-checkbox">
        <input type="checkbox" id="temp-control-option" name="temp-control-option" checked={zone_skipped} onChange={() => onChangeZoneSkipped(!zone_skipped)} />
        <label htmlFor="temp-control-option">Ship my order in a temperature-controlled truck.</label>
      </div>

      { zone_skipped && allow_ice_packs ?
        <>
          <hr />
          <div className="message">
            <p>
              <strong>Need more protection?</strong>
              <br />Add ice packs to your order.
            </p>
            <p>This option keeps your wine protected for the majority of its journey through the hotter climates. Ice Packs will be added to your order when it transfers from our temperature controlled truck to the final parcel carrier. Each bottle will be carefully packed to protect the labels from condensation.</p>
          </div>

          <div className="ui-checkbox">
            <input type="checkbox" id="temp-control-ice-packs" name="temp-control-ice-packs" checked={ice_packs} onChange={() => onChangeIcePacks(!ice_packs)} />
            <label htmlFor="temp-control-ice-packs">
              Add Ice Packs
              { show_label ?
              <>
                &nbsp;<strong>(${label_string})</strong>
              </>
              : null }
            </label>
          </div>
        </>
      : null }
    </>
  );
}

export default TempControlOptional;
