import * as React from 'react';
import classNames from 'classnames';
import ReactDatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

export type InputProps = {
  label?: string;
  id?: string;
  name: string;
  placeholder?: string;
  disabled?: boolean;
  error?: string;
  minDate?: Date;
  maxDate?: Date;

  selected?: Date | null;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: (value: Date | [Date | null, Date | null] | null) => void;
};

const DatePicker = (props: InputProps) => {
  const { label, id, name, selected, placeholder, disabled, error, minDate, maxDate } = props;

  const inputId = id || name;

  const onChange = (date: Date | [Date | null, Date | null] | null, event: React.SyntheticEvent<HTMLInputElement>) => {
    if (props.onChange) props.onChange(date);
  }

  const onBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
    if (props.onBlur) props.onBlur(event);
    return true;
  }

  return (
    <div className={classNames('ui-input date-picker', { 'has-error': error })}>
      { label ? <label htmlFor={inputId}>{label}</label> : null }
      <ReactDatePicker
        id={inputId}
        disabled={disabled}
        placeholderText={placeholder}
        minDate={minDate}
        maxDate={maxDate}
        selected={selected}
        onChange={onChange}
        onBlur={onBlur}
      />
      <span className="input-icon right">
        <FontAwesomeIcon icon={faCalendarAlt} />
      </span>
      { error ? <div className="input-error">{ error }</div> : null }
    </div>
  );
}

export default DatePicker;
