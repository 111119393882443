import * as React from 'react';
import classNames from 'classnames';

import { UseFormRegister } from 'react-hook-form';

export type InputProps = {
  label?: string;
  id?: string;
  name: string;
  type?: string;
  placeholder?: string;
  disabled?: boolean;
  error?: string;
  min?: string;
  max?: string;
  minLength?: number;
  maxLength?: number;

  onBlur?: React.FocusEventHandler<HTMLInputElement>;

  // option 1 (uncontrolled): specify a react-hook-form register
  register?: UseFormRegister<any>;

  // option 2 (controlled): specify value and change handler
  value?: string;
  onChange?: (value: string) => void;
};

const Input = (props: InputProps) => {
  const { label, id, name, type, value, placeholder, register, disabled, error, min, max, minLength, maxLength } = props;

  const inputId = id || name;

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (props.onChange) props.onChange(event.target.value);
  }

  const onBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
    if (props.onBlur) props.onBlur(event);
    return true;
  }

  const input = register
    ? <input type={ type || 'text' } id={inputId} disabled={disabled} placeholder={placeholder} min={min} max={max} minLength={minLength} maxLength={maxLength} {...register(name)} />
    : <input type={ type || 'text' } id={inputId} name={name} value={value == undefined ? '' : value} onChange={onChange} onBlur={onBlur} disabled={disabled} placeholder={placeholder} min={min} max={max} minLength={minLength} maxLength={maxLength} />
  ;

  return (
    <div className={classNames('ui-input', { 'has-error': error })}>
      { label ? <label htmlFor={inputId}>{label}</label> : null }
      { input }
      { error ? <div className="input-error">{ error }</div> : null }
    </div>
  );
}

export default Input;
