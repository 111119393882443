import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { publish } from 'javascripts/utils/events';
import SlideoutCartItems from './SlideoutCartItems';
import useCart from 'javascripts/utils/useCart';
import { generateCostSummary } from 'javascripts/utils/cart';
import CartItem from './CartItem';

const SLIDEOUT_CART_BANNERS: { title?: string, message?: string }[] = (window as any).SLIDEOUT_CART_BANNERS;
const SIGNED_IN = (window as any).USER_ID !== '';

/**
 * Reactive SlideoutCart component that fetches changes dynamically from
 * external sources.
 */
const SlideoutCart = () => {
  const [cart, {}] = useCart();

  const addUpsell = () => {
    if (!cart.upsell) return;
    publish('CART_UPDATE_REQUESTED', {
      saleId: cart.upsell.sale.id,
      quantity: 1,
      upsell: true
    });
  }

  const items = cart.items || [];
  const { costs } = generateCostSummary(cart);

  // check if any items are out of stock or expired
  let disabled = false;
  for (const item of items) {
    if (item.expired) disabled = true;
  }

  return (
    <>
      <div id="slideout-cart" className={`ui-slideout-cart ${ items.length === 0 ? 'empty' : '' } ${ cart.loading ? 'loading' : '' }`} style={{ display: 'none' }}>
        <div className="slideout-cart-background"></div>
        <div className="header">
          <div className="title">My Cart</div>
          <a href="#" className="icon slideout-cart-toggle">
            <FontAwesomeIcon icon={faTimes} />
          </a>
        </div>
        { SLIDEOUT_CART_BANNERS ?
          <div className="body cart-banners">
            { SLIDEOUT_CART_BANNERS.map(banner => (
              <div key={banner.title} className="ui-message warning">
                { banner.title ? <p><strong>{banner.title}</strong></p> : null }
                { banner.message ? <p>{banner.message}</p> : null }
              </div>
            )) }
          </div>
        : null }
        <div className="body cart-items">
          <SlideoutCartItems items={items} />
        </div>
        { cart.upsell?.user_message ?
          <div className="body cart-upsell-message">{ cart.upsell?.user_message }</div>
        : null }
        { cart.upsell ? (
          <div className="body cart-upsell">
            <CartItem item={{ sale: cart.upsell.sale, qty: 1 }} upsell />
            <button className="ui-button small" onClick={addUpsell}>Add to Cart</button>
          </div>
        ) : null }
        <div className="footer">
          <div className="amounts">
            { costs.map(row => (
              <div key={row.name} className={`cost-row cost-${row.type}`}>
                <div className="label">{row.name}</div>
                <div className="amount">${row.value.str}</div>
              </div>
            )) }
          </div>
          { items.length === 0 || disabled ?
            <button className="ui-button block" disabled>Proceed to Checkout</button>
          : !SIGNED_IN ?
            <>
              <a href="/users/login" className="ui-button block">Sign In to Proceed</a>
              <a href="/users/register" className="ui-button block secondary">Register an Account</a>
            </>
          :
            <a href="/checkout" className="ui-button block">Proceed to Checkout</a>
          }
        </div>
      </div>
      <div className="slideout-cart-overlay slideout-cart-toggle" style={{ display: 'none' }}></div>
    </>
  );
};

export default SlideoutCart;
