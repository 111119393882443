import * as React from 'react';
import { round } from 'lodash-es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { publish } from 'javascripts/utils/events';
import SaleThumbnail from './SaleThumbnail';

export type CartItemProps = {
  item: CartItemData;
  editable?: boolean;
  upsell?: boolean;
};

const CartItem = (props: CartItemProps) => {
  const { item, editable, upsell } = props;
  const { sale, qty } = item;

  // calculate item totals with proper rounding
  const itemTotal = round((sale.price.cents * qty) / 100.0);

  const updateCart = (newQty: number) => {
    if (newQty < 0) return;
    publish('CART_UPDATE_REQUESTED', { saleId: sale.id, quantity: newQty, upsell: upsell, price: sale.price });
  }

  return (
    <div className={`ui-cart-item ${ item.expired ? 'expired' : '' }`}>
      <SaleThumbnail sale={sale} />
      <div className="content">
        { upsell ? <div className="upsell-message">Recommendation from our Collection</div> : null }
        <div className="producer">{sale.product.producer.name}</div>
        <div className="name">
          {sale.product.name}
          { sale.product.is_magnum ?
            <div className="badge">Magnum</div>
          : null }
          { sale.product.is_vault ?
            <div className="badge">Vault</div>
          : null }
        </div>
        { !item.expired ?
          <div className="details">
              { editable ?
                <div className="quantity-fields">
                  <button className="quantity-button" disabled={qty === 0} onClick={() => updateCart(qty-1)}>
                      <FontAwesomeIcon icon={faMinus} />
                  </button>
                  <div className="quantity-count">{ qty }</div>
                  <button className="quantity-button" onClick={() => updateCart(qty+1)}>
                      <FontAwesomeIcon icon={faPlus} />
                  </button>
                </div>
              :
                <div className="quantity">
                  { qty }×
                </div>
              }
            <div className="price">
              { qty > 1 ? <span className="unit">(${ sale.price.str })</span> : null }
              <span className="total">${ itemTotal }</span>
            </div>
          </div>
        :
          <div className="details muted">
            <div className="message">This sale has expired.</div>
            <button className="trash-button" onClick={() => updateCart(0)}>
                <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          </div>
        }
      </div>
    </div>
  );
};

export default CartItem;
