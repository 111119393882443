import * as React from 'react';
import { useContext } from 'react';

import { CheckoutContext } from './CheckoutPage';

import CheckoutEmptyPanel from './CheckoutEmptyPanel';
import CheckoutReviewCartPanel from './CheckoutReviewCartPanel';
import CheckoutModePanel from './CheckoutModePanel';
import CheckoutLockerOnlyPanel from './CheckoutLockerOnlyPanel';
import CheckoutLockersPanel from './CheckoutLockersPanel';
import CheckoutShippingPanel from './CheckoutShippingPanel';
import CheckoutShipDatePanel from './CheckoutShipDatePanel';
import CheckoutPaymentPanel from './CheckoutPaymentPanel';
import InstantCheckoutPanel from './InstantCheckoutPanel';

const CheckoutPanels = () => {
  const [state, {}] = useContext(CheckoutContext);

  if (state.order.cart_empty) return (<CheckoutEmptyPanel />);

  if (state.order.settings?.instant_checkout_enabled) return (<InstantCheckoutPanel />);

  return (
    <>
      { !state.order.locker_only ?
        <>
          <CheckoutReviewCartPanel />
          <CheckoutModePanel />
        </>
      : null }
      { state.order.order_type == 'locker_order' ? (
        <CheckoutLockersPanel />
      ) : null }
      { state.order.locker_only ?
        <CheckoutLockerOnlyPanel />
      : null }
      { state.order.has_shipping ? (
        <>
          <CheckoutShippingPanel />
          <CheckoutShipDatePanel />
        </>
      ) : null }
      <CheckoutPaymentPanel />
    </>
  );
};

export default CheckoutPanels;
