import * as React from 'react';
import { useContext } from 'react';
import AddressPanel from './AddressPanel';

import { CheckoutContext } from './CheckoutPage';
import CreditCardPanel from './CreditCardPanel';
import SaleThumbnail from './SaleThumbnail';

const CheckoutThanksPanel = () => {
  const [state, {}] = useContext(CheckoutContext);
  const { user, order, post_purchase } = state;

  if (!order.complete || !post_purchase) return null;

  let purchaseDate = `PLACED ${post_purchase.purchase.created_date}`;

  let purchaseId = `PURCHASE #${post_purchase.purchase.id}`;
  if (post_purchase.order_type === 'single_order') {
    purchaseId = `ORDER #${post_purchase.orders[0].id}`;
  }

  let message = 'Your wine will ship soon.';
  if (post_purchase.order_type === 'locker_order' && post_purchase.purchase.bottles_for_shipping == 0) {
    message = 'Your Lockers have been updated.';
  }

  // was instant checkout enabled by the server from this order?
  const instantCheckoutEnabled = post_purchase.instant_checkout_enabled === true;
  const instantCheckoutCard = state.order.selected_card;
  const instantCheckoutAddress = state.order.has_shipping ? state.order.selected_address : undefined;

  // get a featured bottle from the order
  let featuredSale = state.post_purchase?.purchase?.line_items.find(li => li.sale.product?.has_bottle_image);
  if (!featuredSale) featuredSale = state.post_purchase?.purchase?.line_items[0];

  const referrerAmount = post_purchase.referrer_amount;
  const recipientAmount = post_purchase.recipient_amount;

  return (
    <>
      <div className="ui-panel checkout-panel checkout-thanks">
        <div className="panel-body">
          <div className="grid-row">
            <div className="col-12 col-md-8">
              <h1>Success!</h1>
              <div className="message">
                {message}<br />
                Thanks so much for your order.
              </div>
              <div className="purchase-metadata">
                <span className="purchase-id">{purchaseId}</span>
                <span className="purchase-date">{purchaseDate}</span>
              </div>
            </div>
            <div className="col-12 col-md-4">
              { featuredSale ?
                <>
                  <div className="thanks-featured-image">
                    <SaleThumbnail sale={featuredSale.sale} lineItems={state.post_purchase?.purchase?.line_items} size="medium" />
                  </div>
                </>
              : null }
            </div>
          </div>

          <div className="ui-button-group">
            { post_purchase.order_type === 'locker_order' ?
              <a href="/account/lockers" className="ui-button">View My Lockers</a>
            :
              <a href="/account/orders" className="ui-button">View My Orders</a>
            }
            <a href="/" className="ui-button secondary">Continue Shopping</a>
          </div>
        </div>

        { user.settings && !user.settings.instant_checkout_enabled ?
          <>
            <div className="panel-body muted instant-checkout-intro">
              { instantCheckoutEnabled ?
                <>
                  <h3>Instant Checkout has been enabled for your account.</h3>
                  <p>The next time you order, we’ll automatically preselect your preferred address and card information.</p>

                  <div className="ui-button-group">
                    <a href="/account/checkout_preferences" className="ui-button">Checkout Preferences</a>
                  </div>
                </>
              :
                <>
                  <h3>Speed up your next transaction by enabling Instant Checkout.</h3>
                  <p>Save time on your subsequent purchases by turning on Instant Checkout.</p>

                  <div className="grid-row">
                    <div className="col-12 col-lg-6">
                      <div className="section-label">Default Shipping Address</div>
                      { instantCheckoutAddress ?
                        <AddressPanel address={instantCheckoutAddress} selected showPreferred />
                      :
                        <p className="muted">No shipping address specified.</p>
                      }
                    </div>
                    <div className="col-12 col-lg-6">
                      <div className="section-label">Default Payment Method</div>
                      { instantCheckoutCard ?
                        <CreditCardPanel card={instantCheckoutCard} selected showPreferred />
                      : null }
                    </div>
                  </div>

                  <div className="ui-button-group">
                    <a href="/account/checkout_preferences" className="ui-button">Enable Instant Checkout</a>
                    <a href="/account/checkout_preferences" className="ui-button secondary">Checkout Preferences</a>
                  </div>
                </>
              }
            </div>
          </>
        : null }
      </div>

      { user.referral_program_enabled ?
        <div className="ui-panel checkout-panel checkout-refer-friends">
          <div className="panel-body">
            <div className="grid-row">
              <div className="col-12 col-md-6 col-lg-4">
                <h2>Refer friends, get rewarded!</h2>
              </div>
              <div className="col-12 col-md-6 col-lg-8">
                <p>Know someone who drinks wine? (We hope so!) Let them know about Wine Spies!</p>
                <p>Every friend you invite gets ${recipientAmount} instant credit and YOU get ${referrerAmount} when they make their first purchase.</p>
              </div>
            </div>
            <a href="/account/credits" className="ui-button">Get My Referral Link</a>
          </div>
        </div>
      : null }
    </>
  );
};

export default CheckoutThanksPanel;
