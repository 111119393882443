/**
 * We inject a timestamp key on this object for future use.
 * This will primarily be used to track changes within React.
 * @param data Data object to inject a new key into.
 */
const injectTimestampKey = <T extends { _key: number }>(data: T) => {
  if (data == undefined) return;
  data._key = new Date().getTime();
}

export default injectTimestampKey;
