import * as React from 'react';
import { useContext, useCallback, useEffect } from 'react';

import { publish, subscribe, unsubscribe } from 'javascripts/utils/events';
import { CheckoutContext } from './CheckoutPage';
import LockerPanel from './LockerPanel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const CheckoutLockersPanel = (props: { instantCheckout?: boolean }) => {
  const [state, { setLockerShipping }] = useContext(CheckoutContext);


  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const paramLockers = (params.lockers as string || '').split(',').filter(i => i.trim().length > 0).map(i => parseInt(i.trim()));

  const showLockerDetails = useCallback((locker: LockerData, ship: boolean) => {
    publish('CHECKOUT_LOCKER_DETAILS_MODAL_OPEN', { locker, ship });
    publish('OPEN_MODAL', 'locker-details');
  }, []);

  // subscribe to external toggle requests
  useEffect(() => {
    const token = subscribe('CHECKOUT_LOCKER_SET_SHIPPING', (data) => {
      setLockerShipping(data.locker.index, data.ship);
    });
    return () => unsubscribe(token);
  }, []);

  const lockers = [];
  const mergedLockers = state.preview.lockers || [];
  const hasVaultOnly = state.preview.has_vault_only;
  const showVaultModal = paramLockers.length == 0

  for (const locker of mergedLockers) {
    lockers.push(
      <LockerPanel
        key={locker.index}
        locker={locker}
        lockersForShipping={state.order.lockers_for_shipping_index}
        instantCheckout={props.instantCheckout}
        setLockerShipping={setLockerShipping}
        showLockerDetails={showLockerDetails} />
    )
  }

  if (props.instantCheckout) return (
    <>
      <div className="section-label">Lockers</div>
      <div className="ui-selection-list">
        {lockers}
        {hasVaultOnly &&
          <a className="vault-info-toggle" onClick={() => publish('OPEN_MODAL', 'vault-info')}>
            Why do Vault bottles create new Lockers?
            <FontAwesomeIcon icon={faQuestionCircle} />
          </a>
        }
      </div>
    </>
  );

  return (
    <div className={`ui-panel checkout-panel checkout-lockers ${state.preview.loading ? 'loading' : ''}`}>
      <div className="panel-header">
        <h2>Which lockers should we ship? ({ state.order.locker_only || lockers.length > 2 ? 'REQUIRED' : 'OPTIONAL' })</h2>
      </div>
      <div className="panel-body">
        <div className="ui-selection-list">
          {lockers}
          {hasVaultOnly && showVaultModal &&
            <a className="vault-info-toggle" onClick={() => publish('OPEN_MODAL', 'vault-info')}>
              Why do Vault bottles create new Lockers?
              <FontAwesomeIcon icon={faQuestionCircle} />
            </a>
          }
        </div>
      </div>
    </div>
  );
};

export default CheckoutLockersPanel;
