import * as React from 'react';

export type SaleThumbnailProps = {
  sale: Sale;
  qty?: number;
  size?: string;
  lineItems?: { sale: Sale, qty: number }[];
};

const SaleThumbnail = (props: SaleThumbnailProps) => {
  const { sale, qty, size, lineItems } = props;

  let auxBottleShotLeft: Sale | null = null;
  let auxBottleShotRight: Sale | null = null;
  // look for auxiliary bottle shots to display beside the centered 3.0 bottle shot
  if (sale.product.has_bottle_image && lineItems && lineItems.length > 1) {
    const bottleShots = lineItems.filter(li => li.sale.id !== sale.id && li.sale.product.is_bottle && li.sale.product.has_bottle_image);
    if (bottleShots.length > 0) auxBottleShotLeft = bottleShots[0].sale;
    if (bottleShots.length > 1) auxBottleShotRight = bottleShots[1].sale;
  }

  return (
    <div className={`ui-sale-thumbnail size-${ size || 'small' }`}>
      { sale.product.has_bottle_image ?
        // new backdrop + bottle shot image style
        <div className="stacked-photo">
          <div className="image-backdrop" style={{ backgroundColor: sale.show_featured_color ? sale.product.featured_color : 'transparent' }}>
            { sale.show_featured_image && sale.product.featured_image_url ?
              <img className="backdrop-photo lazyload" src="/loading-block-small.gif" data-error-src="/loading-small-error.svg" data-src={sale.product.featured_image_url} />
            : null }
          </div>
          { auxBottleShotLeft && auxBottleShotLeft.product.bottle_image_url ?
            <div className="bottle-shot aux-left">
              <img className="bottle-photo lazyload" src="/loading-block-small.gif" data-error-src="/loading-small-error.svg" data-src={auxBottleShotLeft.product.bottle_image_url} />
            </div>
          : null }
          { auxBottleShotRight && auxBottleShotRight.product.bottle_image_url ?
            <div className="bottle-shot aux-right">
              <img className="bottle-photo lazyload" src="/loading-block-small.gif" data-error-src="/loading-small-error.svg" data-src={auxBottleShotRight.product.bottle_image_url} />
            </div>
          : null }
          <div className="bottle-shot">
            { sale.product.bottle_image_url ?
              <img className="bottle-photo lazyload" src="/loading-block-small.gif" data-error-src="/loading-small-error.svg" data-src={sale.product.bottle_image_url} alt="Product Photo" />
            : null }
          </div>
        </div>
      :
        // legacy label-only style
        <div className="legacy-photo">
          <img className="lazyload" src="/loading-block-small.gif" data-error-src="/loading-small-error.svg" data-src={sale.product.image_thumb_url} alt="Product Photo" />
      </div>
      }
      { qty ?
        <div className="qty-badge">{qty}</div>
      : null }
    </div>
  );
};

export default SaleThumbnail;
