import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle, faInfoCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import bottleEmpty from '../../assets/images/checkout/bottle-empty.svg';
import bottleFilled from '../../assets/images/checkout/bottle-filled.svg';
import bottleFilledNew from '../../assets/images/checkout/bottle-filled-new.svg';

export type LockerPanelProps = {
  locker: LockerData;
  lockersForShipping?: number[];
  instantCheckout?: boolean;
  showLockerDetails: (locker: LockerData, ship: boolean) => void;
  setLockerShipping: (index: number, ship: boolean) => void;
}

const LockerPanel = (props: LockerPanelProps) => {
  const { locker, lockersForShipping, instantCheckout, showLockerDetails, setLockerShipping } = props;

  let countSummary = 'Locker Full';
  if (!locker.full) countSummary = `${locker.bottle_qty} of 12 bottles`;

  const newLocker = locker.line_items.find(i => !i.new) == undefined;
  const newQty = locker.line_items.filter(i => i.sale.product.is_bottle && i.new).reduce((x, y) => x + y.qty, 0);
  const ship = (lockersForShipping || []).indexOf(locker.index) >= 0;

  const label = ship ? 'Will be Shipped' : locker.full ? 'Ship for Free!' : null;
  const highlightLabel = ship || locker.full;

  const handleToggleClick = (evt: React.MouseEvent<HTMLAnchorElement>) => {
    showLockerDetails(locker, ship);
    evt.preventDefault();
    return false;
  }

  const handleOnChange = () => {
    if (instantCheckout) return;
    setLockerShipping(locker.index, !ship);
  }

  // prep bottle shots array
  const bottleShots: { img: string, alt: string, new: boolean, saleId?: number }[] = [];
  let vaultItem = null;
  for (const item of locker.line_items) {
    if (!item.sale.product.is_bottle) {
      continue;
    }

    if (item.sale.product.is_vault) {
      vaultItem = item;
      continue;
    }

    for (let i = 0; i < item.qty; i++) {
      bottleShots.push({
        img:
          item.sale.product.has_bottle_image && item.sale.product.bottle_image_url
            ? item.sale.product.bottle_image_url
            : (item.new ? bottleFilledNew : bottleFilled),
        alt: item.sale.product.name,
        new: item.new || false,
        saleId: item.sale.id,
      });
    }

    // Remove negative adjustments
    if (item.adjustment && item.qty < 0) {
      for (let i = 0; i > item.qty; i--) {
        var saleId = item.sale.id;

        for (var j = 0; j < bottleShots.length; j++) {
          if (saleId == bottleShots[j].saleId) {
            bottleShots.splice(j, 1);
            break;
          }
        }
      }
    }
  }

  // add empty bottles to bottle shots
  const empty = (12 - bottleShots.length) - (vaultItem ? 1 : 0);
  for (let i = 0; i < empty; i++) {
    bottleShots.push({ img: bottleEmpty, alt: 'Empty', new: false });
  }

  if (vaultItem) bottleShots.push({
    img:
      vaultItem.sale.product.has_bottle_image && vaultItem.sale.product.bottle_image_url
        ? vaultItem.sale.product.bottle_image_url
        : (vaultItem.new ? bottleFilledNew : bottleFilled),
    alt: vaultItem.sale.product.name,
    new: vaultItem.new || false,
    saleId: vaultItem.sale.id,
  });

  return (
    <label className={`ui-selection-panel locker ${ship ? 'selected' : ''} ${instantCheckout ? 'readonly' : ''}`}>
      {label ? <div className={`locker-label ${highlightLabel ? 'red' : ''}`}>{label}</div> : null}
      <input type="checkbox" name={'checkout-locker-' + locker.index} checked={ship} onChange={handleOnChange} className="selection-checkbox" />
      <div className="checkbox unchecked"><FontAwesomeIcon icon={faCircle} /></div>
      <div className="checkbox checked"><FontAwesomeIcon icon={faCheckCircle} /></div>
      <div className="title">
        {countSummary}
        {newQty ?
          <span className="new-qty">({newQty} new)</span>
          : null}
      </div>
      <div className="content">
        {newLocker ? 'New Locker' : locker.created_at ? 'Created ' + locker.created_at : ''}

        <div className="bottles">
          {bottleShots.map((item, i) => (
            <div key={i} className={`bottle-shot ${ item.new ? 'new' : '' }`} title={item.alt}>
              <img src={item.img} alt={item.alt} />
              { item.new ? <span className="new-icon"><FontAwesomeIcon icon={faPlusCircle} /></span> : null }
            </div>
          ))}
        </div>
      </div>
      <div className="locker-footer">
        <div className="id">
          <span className="h-to-xs">Locker&nbsp;</span>
          #{locker.id}
        </div>
        <a href="#" className="details-toggle" onClick={handleToggleClick}>
          <span className="h-from-sm">
            View Locker
          </span>
          <span className="h-to-xs">
            View Locker
            <FontAwesomeIcon icon={faInfoCircle} />
          </span>
        </a>
      </div>
    </label>
  );
};

export default LockerPanel;
