import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { diffWords, Change } from 'diff';

const HighlightDifferences: React.FC<{ text1: string, text2: string }> = ({ text1, text2 }) => {
  const lowerText1 = text1.toLowerCase();
  const lowerText2 = text2.toLowerCase();
  const diff: Change[] = diffWords(lowerText1, lowerText2);

  // Use the original index to preserve the original case
  let originalIndex = 0;

  return (
    <span>
      {diff.map((part, index) => {
        if (part.removed) {
          return null;
        }

        const value = part.value;
        const length = value.length;
        const originalValue = text2.slice(originalIndex, originalIndex + length);
        originalIndex += length;

        return (
          <span
            key={index}
            className={part.added ? 'changed' : undefined}
          >
            {originalValue}
          </span>
        );
      })}
    </span>
  );
};

export type ReferenceAddressData = {
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
}

type AddressCandidatePanelProps = {
  index: number
  referenceAddress?: ReferenceAddressData;
  address: AddressData;
  selected: boolean;
  setSelectedAddressIndex: (index: number | null) => void;
  onAccept: () => void;
};

const AddressCandidatePanel = (props: AddressCandidatePanelProps) => {
  const { index, referenceAddress, address, selected, setSelectedAddressIndex, onAccept } = props;

  const onSelect = () => {
    if (selected) {
      setSelectedAddressIndex(null)
    } else {
      setSelectedAddressIndex(index)
    }
  }

  if (!referenceAddress) return null;

  return (
    <label className={`ui-selection-panel address ${selected ? 'selected' : 'muted'}`}>
      <input type="checkbox" checked={selected} onChange={onSelect} className="selection-checkbox" />
      <div className="checkbox unchecked"><FontAwesomeIcon icon={faCircle} /></div>
      <div className="checkbox checked"><FontAwesomeIcon icon={faCheckCircle} /></div>
      <div className="content">
        <HighlightDifferences text1={referenceAddress.street1} text2={address.street1} /><br />
        {address.street2 && (
          <>
            <HighlightDifferences text1={referenceAddress.street2} text2={address.street2} /><br />
          </>
        )}
        <HighlightDifferences text1={referenceAddress.city} text2={address.city} />{', '}
        <HighlightDifferences text1={referenceAddress.state} text2={address.state} />{' '}
        <HighlightDifferences text1={referenceAddress.zip} text2={address.zip} />
      </div>

      <div className="panel-actions">
        <button type="button" className="panel-action" onClick={onAccept}>
          Accept Suggested Address
        </button>
      </div>
    </label>
  );
}

export default AddressCandidatePanel;
