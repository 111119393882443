import axios from 'axios';
import { debounce } from 'lodash-es';

import injectTimestampKey from 'javascripts/utils/injectTimestampKey';
import { subscribe, publish } from 'javascripts/utils/events';

const LOCAL_CART_KEY = 'tws.cart.local';

const DEFAULT_EMPTY_CART = {
  _key: -1,
  id: 0,
  total_qty: 0,
  shipping_included_threshold: 12,
  subtotal: { str: '0.00', num: 0, cents: 0 },
  estimated_total: { str: '0.00', num: 0, cents: 0 },
  items: []
};

const currencyFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

export const DEFAULT_ADD_TO_CART_QTY = 3

export class CartService {
  pendingUpdate?: CartUpdateData;
  queueCartUpdate: ReturnType<typeof debounce>;
  signedIn: boolean;
  userId?: string;

  cart?: CartData;

  constructor() {
    this.userId = (window as any).USER_ID;
    this.signedIn = this.userId != null && this.userId !== '';

    // subscribe to PubSub cart events
    subscribe('CART_REFRESH', this.getCart);
    subscribe('CART_UPDATE_REQUESTED', this.handleUpdateCart);
    subscribe('CART_UPDATE_BUY_BUTTON_TEXTS', this.updateBuyButtonTexts);

    // prep debounced cart update function
    this.queueCartUpdate = debounce(this.updateCart, 500);

    // actively try to merge local storage cart when signed in
    this.mergeLocalCart();
  }

  /**
   * Initialize the cart service with data.
   * This ensures that all subscribers have received a CART_UPDATED
   * event at least once.
   */
  initialize = async () => {
    await this.getCart();
  }

  /**
   * Fetch cart data from the server.
   */
  getCart = async (view?: boolean) => {
    let cart: CartData = DEFAULT_EMPTY_CART;

    if (this.signedIn) {
      const response = await axios.get(`/cart?view_upsell=${ view == undefined ? false : view }`);
      cart = response.data.cart;
    } else {
      const localCart = this.getLocalCart();
      if (localCart) cart = localCart;
    }

    // update temporary local cart data
    this.cart = cart;

    // find all visible "Add to Cart" buttons and display "In Cart"
    this.updateBuyButtonTexts(cart);

    // delay this by just a tiny bit, since offline cart is now instantaneous and could cause race conditions
    setTimeout(() => {
      injectTimestampKey(cart);
      publish('CART_UPDATED', cart);
    }, 100);
  }

  /**
   * Fetch cart data from localStorage.
   */
  getLocalCart = (): CartData | null => {
    let localCart;

    try {
      localCart = window.localStorage.getItem(LOCAL_CART_KEY);
    } catch (e) {
      console.error('Failed to get local cart:', e);
    }

    if (!localCart) return null;

    return JSON.parse(localCart);
  }

  private updateBuyButtonTexts = (cart?: CartData) => {
    if (!cart) cart = this.cart;
    if (!cart) return;

    document.querySelectorAll('[data-buy-button]').forEach(el => {
      const button = el as HTMLElement;
      const saleId = button.getAttribute('data-sale-id');
      const cartItem = cart?.items.find(i => i.sale.id.toString() === saleId);

      button.innerText = cartItem && cartItem.qty > 0 ? cartItem.qty + ' in Cart' : 'Add to Cart';
    });
  }

  /**
   * Global event handler that queues cart updates.
   * @param update Cart item to be updated.
   */
  private handleUpdateCart = async (update: CartUpdateData) => {
    this.pendingUpdate = update;
    if (update.shouldNotDebounce) {
      this.updateCart();
    } else {
      this.queueCartUpdate();
    }
  }

  /**
   * Perform actual cart update.
   * Should be called only by the debounce function.
   */
  private updateCart = async () => {
    if (!this.pendingUpdate) return;

    try {
      publish('CART_UPDATING');

      const { saleId, quantity, upsell, price, onSuccess } = this.pendingUpdate;
      let cart: CartData | undefined;

      if (this.signedIn) {
        // update cart upstream
        const response = await axios.patch('/cart/update', {
          sale_id: saleId,
          qty: quantity,
          view_upsell: upsell
        });

        cart = response.data.cart;

        this.updateCartGTMDataLayer(cart);

      } else {
        // update localStorage cart
        const response = await axios.get(`/sales/${saleId}/summary`);
        if (response.data) cart = this.updateLocalCart(response.data.sale, quantity == undefined ? DEFAULT_ADD_TO_CART_QTY : quantity);
      }

      if (cart) {
        injectTimestampKey(cart);
        publish('CART_UPDATED', cart);
        if (onSuccess) onSuccess(cart);
        this.updateBuyButtonTexts(cart);
        return cart;
      }

      return null;
    } catch (e) {
      // failed to update the cart, so we just try to refresh the list
      await this.getCart();
    }
  }

  updateCartGTMDataLayer = (cart: CartData | undefined) => {
    if (!cart) return;
    if (!cart.last_added_item) return;

    let cartValue = cart.estimated_total.num;
    let saleId = cart.last_added_item.sale_id;
    let price = cart.last_added_item.price;
    let quantity = cart.last_added_item.qty;

    let coeff = 1000 * 60;
    let rounded = new Date(Math.floor((new Date()).getTime() / coeff) * coeff).getTime() / 1000;
    let eventId = `add_to_cart_${(window as any).USER_ID}_${saleId}_${rounded}`;

    // GA4 Implementation
    (window as any).dataLayer.push({
      event: 'add_to_cart',
      event_id: eventId,
      currency: 'USD',
      value: cartValue,
      items: [{
        item_id: saleId,
        price: price?.num || null,
        quantity: quantity || null
      }],
      cart_total: cartValue,
      cart_line_items: [{
        sale_id: saleId,
        price: price?.num || null,
        quantity: quantity || null
      }],
    });

    // UA Implementation
    (window as any).dataLayer.push({ ecommerce: null });
    (window as any).dataLayer.push({
      event: 'addToCart',
      ecommerce: {
        currencyCode: 'USD',
        add: {
          products: [{
            id: saleId,
            price: price?.num || null,
            quantity: quantity || null
          }]
        }
      }
    });
  }

  /**
   * Fetch cart data from localStorage.
   */
  updateLocalCart = (sale: Sale, qty: number) => {
    if (!sale) return;

    let localCart = this.getLocalCart();
    if (!localCart) localCart = DEFAULT_EMPTY_CART;

    // find existing sale in cart
    const cartItem = localCart.items.find(i => i.sale.id === sale.id);
    if (cartItem) {
      cartItem.qty = qty;
    } else {
      localCart.items.push({ sale, qty });
    }

    // remove empty line items
    localCart.items = localCart.items.filter(i => i.qty > 0);

    // update local cart values
    localCart.total_qty = localCart.items.reduce((sum, i) => sum + i.qty, 0);
    const totalCents = localCart.items.reduce((sum, i) => sum + (i.qty * i.sale.price.cents), 0.0);
    const totalMoney = { str: currencyFormatter.format(totalCents / 100.0), num: totalCents / 100.0, cents: totalCents };
    localCart.subtotal = totalMoney;
    localCart.estimated_total = totalMoney;

    const cartData = JSON.stringify(localCart);
    window.localStorage.setItem(LOCAL_CART_KEY, cartData);

    return localCart;
  }

  /**
   * Try to upload local storage cart while it exists.
   */
  mergeLocalCart = async () => {
    if (!this.signedIn) return;

    const cart = this.getLocalCart();
    if (!cart || cart.items.length === 0) return;

    await this.recursiveMerge(cart.items);
  }

  /**
   * Recursively upload local cart items, repeating continuously until each cart item is uploaded.
   */
  recursiveMerge = async (items: CartItemData[], attempts = 0) => {
    // we failed too much already; this is probably gonna go on forever so we just abort
    if (attempts > 5) return;

    const added: CartItemData[] = [];
    for (const item of items) {
      try {
        const response = await axios.patch('/cart/update', {
          sale_id: item.sale.id,
          qty: item.qty
        });

        if (response.status === 200) added.push(item);
      } catch (e) {
        // failed to upload entry (will be retried later on)
      }
    }

    // check if we failed to fetch anything
    if (items.length > added.length) {
      // remove already-processed cart items then retry the merge
      const remainingItems = items.filter(i => !added.includes(i));
      await this.recursiveMerge(remainingItems, attempts+1);
    } else {
      // all good, clear the local cart completely and return
      window.localStorage.removeItem(LOCAL_CART_KEY);
      await this.getCart();
    }
  }
}

const cartService = new CartService();
export default cartService;
