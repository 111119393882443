import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSnowflake, faTruck, faWarehouse } from '@fortawesome/free-solid-svg-icons';

export type TempControlRequiredProps = {
  allow_ice_packs?: boolean;
  ice_packs: boolean;
  zone_skipped: boolean;
  onChange: (ice_packs: boolean) => void;
  show_label?: boolean;
  label_string?: string;
};

const TempControlRequired = (props: TempControlRequiredProps) => {
  const { allow_ice_packs, ice_packs, zone_skipped, onChange, show_label, label_string } = props;

  return (
    <>
      <div className="temp-control-viz">
        <div className="terminal active">
          <FontAwesomeIcon icon={faWarehouse} />
        </div>
        <div className="node active"></div>

        <div className={`path large ${ zone_skipped ? 'active' : '' }`}>
          <div className="path-node">
            <FontAwesomeIcon icon={faTruck} />
          </div>
        </div>
        <div className={`node ${ zone_skipped ? 'active' : '' }`}></div>

        { allow_ice_packs ?
        <>
          <div className={`path ${ ice_packs ? 'active' : '' }`}>
            <div className="path-node">
              <FontAwesomeIcon icon={faSnowflake} />
            </div>
          </div>
          <div className={`node ${ ice_packs ? 'active' : '' }`}></div>
        </>
        :
        <>
          <div className={`path ${ zone_skipped ? 'active' : '' }`}/>
          <div className={`node ${ zone_skipped ? 'active' : '' }`}/>
        </>
        }

        <div className={`terminal ${ (allow_ice_packs ? ice_packs : zone_skipped) ? 'active' : '' }`}>
          <FontAwesomeIcon icon={faHome} />
        </div>
      </div>

      <div className="message">
        <p><strong>Temperature Controlled Shipping, included free.</strong></p>
        <p>Your wine will travel on a refrigerated truck from Sonoma County, California to a regional hub near you. Your wine will spend the last 1-2 days of its journey in a standard carrier vehicle. This temperature controlled service may add a few additional days of travel time.</p>
      </div>

      { allow_ice_packs ?
        <>
          <div className="message">
            <p>
              <strong>Need more protection?</strong>
              <br />Add ice packs to your order.
            </p>
            <p>This option keeps your wine protected for the majority of its journey through the hotter climates. Ice Packs will be added to your order when it transfers from our temperature controlled truck to the final parcel carrier. Each bottle will be carefully packed to protect the labels from condensation.</p>
          </div>

          <div className="ui-checkbox">
            <input type="checkbox" id="temp-control-ice-packs" name="temp-control-ice-packs" checked={ice_packs} onChange={() => onChange(!ice_packs)} />
            <label htmlFor="temp-control-ice-packs">
              Add Ice Packs
              { show_label ?
                <>
                  &nbsp;<strong>(${label_string})</strong>
                </>
              : null }
            </label>
          </div>
        </>
        : null }
    </>
  );
}

export default TempControlRequired;
