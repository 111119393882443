import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSnowflake, faTruck, faWarehouse } from '@fortawesome/free-solid-svg-icons';

export type TempControlForbiddenProps = {
  allow_ice_packs?: boolean;
  ice_packs: boolean;
  onChangeIcePacks: (ice_packs: boolean) => void;
  show_label?: boolean;
  label_string?: string;
};

const TempControlForbidden = (props: TempControlForbiddenProps) => {
  const { allow_ice_packs, ice_packs, onChangeIcePacks, show_label, label_string } = props;

  return (
    <>
      <div className="temp-control-viz">
        <div className="terminal active">
          <FontAwesomeIcon icon={faWarehouse} />
        </div>
        <div className="node active"></div>

        { allow_ice_packs ?
        <>
          <div className={`path ${ ice_packs ? 'active' : '' }`}>
            <div className="path-node">
              <FontAwesomeIcon icon={faSnowflake} />
            </div>
          </div>
          <div className={`node ${ ice_packs ? 'active' : '' }`}></div>
        </>
        :
        <>
          <div className="path"/>
          <div className="node"/>
        </>
        }

        <div className={`terminal ${ ice_packs ? 'active' : '' }`}>
          <FontAwesomeIcon icon={faHome} />
        </div>
      </div>

      { allow_ice_packs ?
        <>
          <div className="message">
            <p>Protect your wine from heat with ice packs.</p>
            <p>With this option, your wine will leave our warehouse with ice packs in the box. Each bottle will be carefully wrapped to protect the labels from condensation.</p>
          </div>

          <div className="ui-checkbox">
            <input type="checkbox" id="temp-control-ice-packs" name="temp-control-ice-packs" checked={ice_packs} onChange={() => onChangeIcePacks(!ice_packs)} />
            <label htmlFor="temp-control-ice-packs">
              Add Ice Packs
              { show_label ?
              <>
                &nbsp;<strong>(${label_string})</strong>
              </>
              : null }
            </label>
          </div>
        </>
        :
        <div className="message">
          <p>
            <strong>Temperature Controlled Shipping is Unavailable.</strong>
          </p>
          <p>We're sorry, but due to shipping carrier rules, there are no temperature controlled shipping options available for your state. If you have any questions, please <a href="/support" className="support">contact support</a>.</p>
        </div>
      }
    </>
  );
}

export default TempControlForbidden;
