import { library, dom } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowLeft, faArrowRight,
  faChevronDown, faChevronUp, faChevronRight,
  faCreditCard, faSearch, faQuestionCircle, faCheck,
  faCheckCircle, faCalendarAlt, faTimesCircle,
  faAsterisk, faInfo, faInfoCircle, faTimes,
  faWarehouse, faTruck, faTruckLoading, faSnowflake, faHome,
  faMinus, faPlus, faPlusCircle, faTrashAlt, faSquare, faDollarSign,
  faUndo, faUndoAlt, faLock, faBox, faBoxOpen,
  faClock, faBan, faSearchPlus, faSync, faSyncAlt
} from '@fortawesome/free-solid-svg-icons';

import {
  faCircle, faClock as faClockRegular
} from '@fortawesome/free-regular-svg-icons';

import {
  faFacebookF, faFacebookSquare, faFacebookMessenger,
  faTwitterSquare, faInstagramSquare, faGoogle,
  faCcVisa, faCcMastercard,
  faCcAmex, faCcDinersClub,
  faCcJcb, faCcDiscover,
  faCcAmazonPay, faCcApplePay
} from '@fortawesome/free-brands-svg-icons'

// we import specific icons only so webpack can treeshake the rest
library.add(
  // solid
  faArrowLeft, faArrowRight,
  faChevronDown, faChevronUp, faChevronRight,
  faCreditCard, faSearch, faQuestionCircle, faCheck,
  faCheckCircle, faCalendarAlt, faTimesCircle,
  faAsterisk, faInfo, faInfoCircle, faTimes,
  faWarehouse, faTruck, faTruckLoading, faSnowflake, faHome,
  faMinus, faPlus, faPlusCircle, faTrashAlt, faSquare, faDollarSign,
  faUndo, faUndoAlt, faLock, faBox, faBoxOpen,
  faClock, faBan, faSearchPlus, faSync, faSyncAlt,

  // regular
  faCircle, faClockRegular,

  // brands
  faFacebookF, faFacebookSquare, faFacebookMessenger,
  faTwitterSquare, faInstagramSquare, faGoogle,
  faCcVisa, faCcMastercard,
  faCcAmex, faCcDinersClub,
  faCcJcb, faCcDiscover,
  faCcAmazonPay, faCcApplePay
);

dom.watch();
