import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';

import { publish, subscribe, unsubscribe } from 'javascripts/utils/events';
import checkoutService from 'javascripts/services/checkout-service';
import LockerPanel from './LockerPanel';

const LockerManagement = () => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [lockers, setLockers] = useState<LockerData[]>([]);
  const [lockersForShipping, setLockersForShipping] = useState<number[]>([]);

  const setLockerShipping = useCallback((lockerIndex: number, ship: boolean) => {
    const newLockersForShipping = [...lockersForShipping];

    const i = newLockersForShipping.indexOf(lockerIndex);
    const shipped = i != undefined && i >= 0;

    if (ship && !shipped) newLockersForShipping.push(lockerIndex);
    if (!ship && shipped) newLockersForShipping.splice(i, 1);

    setLockersForShipping(newLockersForShipping.sort());
  }, [lockers, setLockers, lockersForShipping]);

  // subscribe to external toggle requests
  useEffect(() => {
    const token = subscribe('CHECKOUT_LOCKER_SET_SHIPPING', (data) => {
      setLockerShipping(data.locker.index, data.ship);
    });
    return () => unsubscribe(token);
  }, []);

  const showLockerDetails = useCallback((locker: LockerData, ship: boolean) => {
    publish('CHECKOUT_LOCKER_DETAILS_MODAL_OPEN', { locker, ship });
    publish('OPEN_MODAL', 'locker-details');
  }, []);

  // initialize user locker data
  useEffect(() => {
    checkoutService.getUserDetails()
      .then(data => {
        setLockers(data.lockers);
      })
      .catch(err => {
        setError(err.message || err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) return (
    <div className="panel-body muted">
      <div className="ui-selection-list">
        <div className="selection-empty">
          Please wait...
        </div>
      </div>
    </div>
  );

  if (error) return (
    <div className="panel-body muted">
      <div className="ui-selection-list">
        <div className="selection-empty">
          Failed to fetch locker info. Please try again later.
        </div>
      </div>
    </div>
  );

  if (lockers.length === 0) return (
    <div className="panel-body muted">
      <div className="ui-selection-list">
        <div className="selection-empty">
          You currently have no lockers.
        </div>
      </div>
    </div>
  );

  // lockers exist
  let lockerPanels = [];
  for (const locker of lockers) {
    lockerPanels.push(
      <LockerPanel
        key={locker.index}
        lockersForShipping={lockersForShipping}
        locker={locker}
        setLockerShipping={setLockerShipping}
        showLockerDetails={showLockerDetails} />
    );
  }

  return (
    <>
      <div className="panel-body muted">
        <div className="ui-selection-list">
          {lockerPanels}
        </div>
      </div>
      <div className="panel-footer">
        <div className="grid-row">
          <div className="col"></div>
          <div className="col-12 col-sm-auto">
            <form action="/checkout" method="GET">
              <input type="hidden" name="lockers" required value={lockersForShipping.join(',')} />
              <button type="submit" className="ui-button block" disabled={lockersForShipping.length === 0}>Ship Lockers</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default LockerManagement;