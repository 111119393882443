import gsap from 'gsap';
import { subscribe, publish } from 'javascripts/utils/events';

// listen to modal open events
const openModal = (modalId: string) => {
  const root = document.querySelector(`[data-modal=${modalId}]`);
  if (!root) return console.error(`Unable to find modal '${modalId}'.`);

  root.setAttribute('data-modal-open', 'true');
  const modal = root.querySelector('.ui-modal');

  document.body.classList.add('noscroll');

  // opening animation sequence
  gsap.set(root, { opacity: 0, display: 'block' });
  gsap.to(root, {
    opacity: 1,
    duration: 0.5
  });

  gsap.set(modal, { y: 64, opacity: 0, display: 'block' });
  gsap.to(modal, {
    opacity: 1,
    y: 0,
    duration: 0.5,
    delay: 0.25,
    ease: 'expo.out',
    onComplete: () => {
      publish('MODAL_OPENED', modalId);
    }
  });
};

subscribe('OPEN_MODAL', (modalId) => openModal(modalId));

// listen to modal close events and clicks
const closeModal = (modalId: string) => {
  const root = document.querySelector(`[data-modal=${modalId}]`);
  if (!root) return console.error(`Unable to find modal '${modalId}'.`);

  const modal = root.querySelector('.ui-modal') as HTMLElement;

  // closing animation sequence
  gsap.to(modal, {
    opacity: 0,
    y: 64,
    duration: 0.5,
    ease: 'expo.in'
  });

  gsap.to(root, {
    opacity: 0,
    duration: 0.5,
    delay: 0.5,
    clearProps: 'all',
    onComplete: () => {
      root.removeAttribute('data-modal-open');
      document.body.classList.remove('noscroll');
      publish('MODAL_CLOSED', modalId);
    }
  });
}

subscribe('CLOSE_MODAL', (modalId) => closeModal(modalId));

document.addEventListener('mousedown', evt => {
  const clickTarget = evt.target as HTMLElement;
  if (!clickTarget) return;

  let modalId: string | null = null;
  let targetModal: HTMLElement | null = null;

  // clicked on the modal root itself
  if (clickTarget.hasAttribute('data-modal')) {
    targetModal = clickTarget;
    modalId = targetModal.getAttribute('data-modal');
  }

  // clicked on the intermediate centering wrapper
  if (clickTarget.classList.contains('ui-modal-container')) {
    targetModal = clickTarget.parentElement;
    if (targetModal) modalId = targetModal.getAttribute('data-modal');
  }

  // clicked on an ad-hoc toggle button
  const closest = clickTarget.closest('[data-modal-toggle]');
  if (closest) {
    modalId = closest.getAttribute('data-modal-toggle');
    if (modalId && modalId !== 'true') {
      // modal ID is defined in the attribute
      targetModal = document.querySelector(`[data-modal=${modalId}]`);
    } else {
      // try to get the nearest modal ancestor
      targetModal = closest.closest('[data-modal]');
      if (targetModal) modalId = targetModal.getAttribute('data-modal');
    }
  }

  if (modalId && targetModal) {
    if (targetModal.getAttribute('data-modal-open') === 'true') {
      closeModal(modalId);
    } else {
      openModal(modalId);
    }
  }
});
