import { publish } from 'javascripts/utils/events';

const onLoad = async () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  if (params['welcome'] === 'true') {
    publish('OPEN_MODAL', 'redesign-announcement');
  }
}

if (document.readyState === 'complete') {
  onLoad();
} else {
  document.addEventListener('readystatechange', () => {
    if (document.readyState === 'interactive') onLoad();
    if (document.readyState === 'complete') onLoad();
  });
}
