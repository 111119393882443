import axios from 'axios';
import injectTimestampKey from 'javascripts/utils/injectTimestampKey';

export class CheckoutService {
  /**
   * Fetch checkout user details from the server.
   */
  getUserDetails = async () => {
    const response = await axios.get('/checkout/user_details');

    const userDetails: CheckoutUserDetails = response.data.checkout_details;
    injectTimestampKey(userDetails);
    return userDetails;
  }

  /**
   * Perform a preview checkout against the server to retrieve the
   * order calculations for the current checkout state.
   */
  previewCheckout = async (state: CheckoutState) => {
    // Don't fetch when cart or user_details are still loading.
    if (state.cart.loading || state.user.loading) return;

    const order: OrderAttributes = this.getOrderAttributes(state);
    const data = {
      order_type: state.order.order_type,
      order,
      options: {
        lockers_for_shipping_index: state.order.lockers_for_shipping_index || []
      }
    };
    const response = await axios.post('/checkout/preview', data);

    const preview: CheckoutPreview = response.data;
    injectTimestampKey(preview);
    return preview;
  }

  completeCheckout = async (state: CheckoutState) => {
    // Don't fetch when cart or user_details are still loading.
    if (state.cart.loading || state.user.loading) return;

    const order: OrderAttributes = this.getOrderAttributes(state);
    const data = {
      order_type: state.order.order_type,
      order,
      options: {
        lockers_for_shipping_index: state.order.order_type === 'locker_order' ? state.order.lockers_for_shipping_index || [] : []
      }
    };

    const response = await axios.post('/checkout/complete', data);

    if (response) this.purchaseGTMDataLayer(response);

    return response;
  }

  private purchaseGTMDataLayer = (response: any) => {
    const { purchase } = response.data;

    // GA4 Implementation
    (window as any).dataLayer.push({ ecommerce: null });
    (window as any).dataLayer.push({
      event: 'purchase',
      event_id: `purchase_${purchase.purchase_id}`,
      purchase_id: purchase.purchase_id,
      purchase_total: purchase.total.num,
      shipping: purchase.shipping?.num || 0,
      tax: purchase.tax?.num || 0,
      coupon_code: purchase.coupon_code || null,
      coupon_discount: purchase.coupon_discount?.num || 0,
      items: purchase.line_items.map((item: any) => ({
        item_id: item.sale.id,
        item_name: item.sale.product.name,
        price: item.sale.price.num,
        quantity: item.qty
      })),
      purchase_line_items: purchase.line_items.map((item: any) => ({
        sale_id: item.sale.id,
        product_name: item.sale.product.name,
        price: item.sale.price.num,
        quantity: item.qty
      })),
      ecommerce: {
        purchase: {
          actionField: {
            id: purchase.purchase_id,
            revenue: purchase.total.num,
            tax: purchase.tax?.num || 0,
            shipping: purchase.shipping?.num || 0,
            coupon: purchase.coupon_code || null
          },
          products: purchase.line_items.map((item: any) => ({
            id: item.sale.id,
            name: item.sale.product.name,
            price: item.sale.price.num,
            quantity: item.qty
          }))
        }
      },
    });
  }

  /**
   * Maps local order data into server-format order attributes.
   * @returns Order attributes to be passed to the server as JSON data.
   */
  private getOrderAttributes = (state: CheckoutState) => {
    const { cart, order, user } = state;

    const line_items = cart.items?.map(item => ({
      sale_id: item.sale.id,
      qty: item.qty
    })) || [];

    const attrs: OrderAttributes = {
      line_items_attributes: line_items,

      charge_source: order.selected_card?.id,
      coupon_code: order.coupon_code,

      at_cost_shipping: order.at_cost_shipping
    };

    if (order.has_shipping) {
      attrs.shipping_address_id = order.selected_address?.id;
      attrs.zone_skipped = order.zone_skipped;
      attrs.ice_packs = order.ice_packs;
      attrs.ship_on = order.ship_on;
    }

    if (user.at_cost) {
      attrs.at_cost_shipping = order.at_cost_shipping;
    }

    return attrs;
  }
}

const checkoutService = new CheckoutService();
export default checkoutService;
